/*

? First, let's import the interface that will describe the shape of our employee object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the employee object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const employee: StateInterface["employee"] = {
  _id: "",
  contact: {
    work: {
      country_code: "",
      phone: "",
      email: "",
      address: {
        line1: "",
        line2: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
      },
    },
    personal: {
      country_code: "",
      phone: "",
      email: "",
      address: {
        line1: "",
        line2: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
      },
    },
  },
  employee_id: "",
  first_name: "",
  last_name: "",
  blood_group: "",
  department: "",
  designation: "",
  emergency_contact: {
    country_code: "",
    phone: "",
    email: "",
    address: {
      line1: "",
      line2: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
    },
  },
  identification: {
    aadhaar: {
      number: "",
      front_file_id: "",
      back_file_id: "",
    },
    pan: {
      number: "",
      front_file_id: "",
      back_file_id: "",
    },
    passport: {
      number: "",
      front_file_id: "",
      back_file_id: "",
    },
  },
  date_of_birth: "",
  photo_file_id: "",
  date_of_joining: "",
  date_of_exit: "",
  notes: "",
  test: false,
  job_status: {
    on_payroll: false,
    reason: "",
  },
  created_at: "",
  updated_at: "",
};