/*

? First, let's import the AnyAction type to make it easier to describe the shape of our actions.

*/

import { AnyAction } from "redux";
import { BoostInterface, BoostLogInterface, PropertyInterface, ServiceInterface } from "../models";

/*

? Next, we will import the interface that will describe the shape of our global state.

*/

import StateInterface from "./state-interface";
import {
	BoostLocation,
	BoostPricingManualOverrideInterface,
	BusinessProfileInterface,
	CtaLeadsInterface,
	KYCInformationBusinessProfile,
	Page,
	PostInterface,
	UserInterface,
} from "./sub-interfaces/sub-interfaces";
// import { homePage } from "./sub-state-data/sub-state-data";
import { ObjectId } from "mongodb";
import { PropertyInterface as BoostPropertyInterface } from "./sub-interfaces/property-interface";
import { SearchApiResponseInterface, SearchFilterId, SearchFilterOption } from "./sub-interfaces/search-types";
import { boost } from "./sub-state-data/sub-state-data";

/*

& Now, we can describe all our reducers.

* Reducers are objects with two properties - the reducer function itself and the prepare callback.

* The reducer in our case uses the Immer library which allows us to conveniently mutate the immutable redux store. Alternatively, we could return a new object with the new state.

* The prepare callback is a function that is called before the reducer. It is used to "prepare" the payload that the reducer will receive. Think of it like a "before" hook.

*/

export const changeMe = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.tester = "tester-new-value" + action.payload;
	},
	prepare: (payload: string) => ({ payload: payload }),
};

export const setThemeReducer = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.user.settings.site_preferences.default_theme = action.payload;
	},
	prepare: (payload: string) => ({ payload: payload }),
};

export const fetchSingleProperty = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile = action.payload.user_profile;
		state.single_business_profile = action.payload.business_profile;
		state.single_property_page = action.payload;
		state.location = {
			location: [
				action.payload.property.search_metrics.location_coordinates[1],
				action.payload.property.search_metrics.location_coordinates[0],
			],
		};
	},
	prepare: (payload: StateInterface["single_property_page"]) => ({
		payload: payload,
	}),
};

export const CreateNewPost = {
	reducer: (state: StateInterface, action: AnyAction) => {
		if (action.payload.is_business_profile) {
			state.single_business_profile.posts = action.payload.posts;
			state.postSwrForBusiness = action.payload.posts;
		} else {
			state.single_user_profile.posts = action.payload.posts;
			state.postSwrForUser = action.payload.posts;
		}
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => {
		return {
			payload: payload.payload,
		};
	},
};

export const CreateNewComments = {
	reducer: (state: StateInterface, action: AnyAction) => {
		if (action.payload.payload.path.includes("post")) {
			state.single_post_page.post.comments = action.payload.payload.comments;
		} else {
			if (action.payload.payload.is_business_profile) {
				if (action.payload.tabValue === 0) {
					const postSwrIndex: number = state.single_business_profile.posts.findIndex(
						(post: PostInterface) => post._id === action.payload.payload[0]._id,
					);
					state.single_business_profile.posts[postSwrIndex].comments = action.payload.payload[0].comments;
				} else {
					if (action.payload.payload.path.includes("users")) {
						const postSwrIndex: number = state.single_user_profile.saved_items.posts.findIndex(
							(post: PostInterface) => post._id === action.payload.payload[0]._id,
						);
						state.single_user_profile.saved_items.posts[postSwrIndex].comments = action.payload.payload[0].comments;
					} else {
						const postSwrIndex: number = state.single_business_profile.saved_items.posts.findIndex(
							(post: PostInterface) => post._id === action.payload.payload[0]._id,
						);
						state.single_business_profile.saved_items.posts[postSwrIndex].comments = action.payload.payload[0].comments;
					}
				}
			} else {
				if (action.payload.tabValue === 0) {
					const postSwrIndex: number = state.single_user_profile.posts.findIndex(
						(post: PostInterface) => post._id === action.payload.payload[0]._id,
					);
					state.single_user_profile.posts[postSwrIndex].comments = action.payload.payload[0].comments;
				} else {
					if (action.payload.payload.path.includes("users")) {
						const postSwrIndex: number = state.single_user_profile.saved_items.posts.findIndex(
							(post: PostInterface) => post._id === action.payload.payload[0]._id,
						);
						state.single_user_profile.saved_items.posts[postSwrIndex].comments = action.payload.payload[0].comments;
					} else {
						const postSwrIndex: number = state.single_business_profile.saved_items.posts.findIndex(
							(post: PostInterface) => post._id === action.payload.payload[0]._id,
						);
						state.single_business_profile.saved_items.posts[postSwrIndex].comments = action.payload.payload[0].comments;
					}
				}
			}
		}
	},
	prepare: (payload: {
		tabValue: number;
		response: {
			status: string;
			payload: any;
			missingFields: Array<string> | null;
			error: string | null;
		};
	}) => ({
		payload: { payload: payload.response.payload, tabValue: payload.tabValue },
	}),
};
export const setImageCategory = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.category = action.payload;
	},
	prepare: (payload: StateInterface["category"]) => ({ payload: payload }),
};
// export const VerifyEmail = {
//   reducer: (state: StateInterface, action: AnyAction) => {
//     state.category = action.payload;
//   },
//   prepare: (payload: StateInterface["verify_email"]) => ({ payload: payload }),
// };

export const setLanguage = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.default_language = action.payload;
	},
	prepare: (payload: StateInterface["default_language"]) => ({
		payload: payload,
	}),
};

export const setImagesIndex = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.images_index = action.payload;
	},
	prepare: (payload: StateInterface["images_index"]) => ({ payload: payload }),
};

export const KycProfileTypes = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.kyc_types = action.payload;
	},
	prepare: (payload: StateInterface["kyc_types"]) => ({ payload: payload }),
};

export const setTabValue = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.tabValue = action.payload;
	},
	prepare: (payload: StateInterface["tabValue"]) => ({ payload: payload }),
};

export const setSaveTabValue = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.saved_tabValue = action.payload;
	},
	prepare: (payload: StateInterface["saved_tabValue"]) => ({
		payload: payload,
	}),
};
export const setListingTabValue = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.listing_tabValue = action.payload;
	},
	prepare: (payload: StateInterface["listing_tabValue"]) => ({
		payload: payload,
	}),
};
export const setAnalyticsTabValue = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.analytics_tabValue = action.payload;
	},
	prepare: (payload: StateInterface["analytics_tabValue"]) => ({
		payload: payload,
	}),
};

export const setUserLocation = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.user_location = action.payload;
	},
	prepare: (payload: StateInterface["user_location"]) => ({ payload: payload }),
};

export const fetchSingleBusinessProfile = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile = action.payload.user_profile;
		state.single_business_profile = action.payload;
		state.single_business_profile.properties_og = action.payload.properties;
		state.single_business_profile.services_og = action.payload.services;
		state.single_business_profile.ofb_properties_og = action.payload.ofb_properties;
	},
	prepare: (payload: StateInterface["single_business_profile"]) => ({
		payload: payload,
	}),
};

export const fetchGlobalSearchResults = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.global_search_results = action.payload;
	},
	prepare: (payload: StateInterface["global_search_results"]) => ({
		payload: payload,
	}),
};

export const loadMoreGlobalSearchResults = {
	reducer: (state: StateInterface, action: AnyAction) => {
		Array.isArray(state.global_search_results.results)
			? ((state.global_search_results.number_of_results = action.payload.number_of_results),
				(state.global_search_results.search_duration = action.payload.search_duration),
				state.global_search_results.results.push(...action.payload.results))
			: (state.global_search_results = action.payload);
	},
	prepare: (payload: StateInterface["global_search_results"]) => ({
		payload: payload,
	}),
};

export const LoadingMoreUserProfilePosts = {
	reducer: (state: StateInterface, action: AnyAction) => {
		Array.isArray(state.single_user_profile.posts)
			? ((state.single_user_profile.postsCount = action.payload.postsCount),
				//@ts-ignore
				state.single_user_profile.posts.push(...action.payload.posts))
			: (state.single_user_profile.posts = action.payload.posts);
	},
	prepare: (payload: any) => ({
		payload: payload.payload,
	}),
};

export const loadMoreGlobalSearchPosts = {
	reducer: (state: StateInterface, action: AnyAction) => {
		!Array.isArray(state.global_search_results.results)
			? ((state.global_search_results.number_of_results = action.payload.number_of_results),
				(state.global_search_results.search_duration = action.payload.search_duration),
				state.global_search_results.results.posts.push(...action.payload.results.posts))
			: (state.global_search_results = action.payload);
	},
	prepare: (payload: StateInterface["global_search_results"]) => ({
		payload: payload,
	}),
};

export const setIsSearching = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.is_searching = action.payload;
	},
	prepare: (payload: StateInterface["is_searching"]) => ({
		payload: payload,
	}),
};

export const setSearchResultsPageNumber = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.search_results_page_number = action.payload;
	},
	prepare: (payload: StateInterface["search_results_page_number"]) => ({
		payload: payload,
	}),
};

export const setIsLoading = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.is_loading = action.payload;
	},
	prepare: (payload: StateInterface["is_loading"]) => ({
		payload: payload,
	}),
};

export const CreateNewReview = {
	reducer: (state: StateInterface, action: AnyAction) => {
		if (action.payload.source.includes("properties" && "/reviews")) {
			state.property_reviews_page.property_reviews.push(
				//@ts-ignore
				action.payload.payload,
			);
		} else if (action.payload.source.includes("properties")) {
			if (action.payload.payload.type === "neighbourhood") {
				state.single_property_page.neighbourhoodReviews.push(action.payload.payload);
			} else {
				state.single_property_page.propertyReviews.push(action.payload.payload);
			}
		}
		if (action.payload.source.includes("/services" && "/reviews")) {
			state.service_reviews_page.service_reviews.push(
				//@ts-ignore
				action.payload.payload,
			);
		} else if (action.payload.source.includes("services")) {
			//@ts-ignore
			state.single_service_page.service_reviews.push(action.payload.payload);
		} else if (action.payload.source.includes("users")) {
			//@ts-ignore
			state.single_user_profile.reviews.push(action.payload.payload);
		} else if (action.payload.source.includes("neighbourhood-reviews")) {
			//@ts-ignore
			state.neighbourhood_reviews_page.reviews.push(action.payload.payload);
		} else {
			//@ts-ignore
			state.single_business_profile.reviews.push(action.payload.payload);
		}
	},
	prepare: (payload: {
		source: string;
		response: {
			status: string;
			payload: StateInterface["review"];
			missingFields: Array<string> | null;
			error: string | null;
		};
	}) => ({
		payload: { payload: payload.response.payload, source: payload.source },
	}),
};

export const fetchPropertyReviewsPageData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.property_reviews_page = action.payload;
	},
	prepare: (payload: StateInterface["property_reviews_page"]) => ({
		payload: payload,
	}),
};

export const fetchSingleUserProfile = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile = action.payload;
	},
	prepare: (payload: StateInterface["single_user_profile"]) => ({
		payload: payload,
	}),
};

export const Setintro = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.intro = action.payload;
	},
	prepare: (payload: StateInterface["intro"]) => ({
		payload: payload,
	}),
};

export const fetchBoostPostPageData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.boost_post_page = action.payload;
	},
	prepare: (payload: StateInterface["boost_post_page"]) => ({
		payload: payload,
	}),
};

export const fetchBoostUserProfilePageData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.boost_user_profile_page = action.payload;
	},
	prepare: (payload: StateInterface["boost_user_profile_page"]) => ({
		payload: payload,
	}),
};

export const fetchBoostBusinessProfilePageData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.boost_business_profile_page = action.payload;
	},
	prepare: (payload: StateInterface["boost_business_profile_page"]) => ({
		payload: payload,
	}),
};

export const fetchBoostServicePageData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.boost_service_page = action.payload;
	},
	prepare: (payload: StateInterface["boost_service_page"]) => ({
		payload: payload,
	}),
};

export const fetchBoostPropertyPageData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.boost_property_page = action.payload;
	},
	prepare: (payload: StateInterface["boost_property_page"]) => ({
		payload: payload,
	}),
};

export const fetchSingleServicePageData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page = action.payload;
	},
	prepare: (payload: StateInterface["single_service_page"]) => ({
		payload: payload,
	}),
};

export const fetchSingleServiceImages = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data._id = action.payload.service_data._id;
		state.single_service_page.service_data.slug = action.payload.service_data.slug;
		state.single_service_page.service_data.title = action.payload.service_data.title;
		state.single_service_page.service_data.images = action.payload.service_data.images;
		state.single_service_page.service_data.published = action.payload.service_data.published;
		state.single_service_page.service_data.saved_content = action.payload.service_data.saved_content;
		state.single_service_page.service_data.show_contact_details = action.payload.service_data.show_contact_details;
		state.service_loading.singleServiceImages = false;
	},
	prepare: (
		payload: {
			service_data: ServiceInterface;
		} | null,
	) => ({
		payload: payload,
	}),
};

export const fetchSingleServiceDetails = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.location.title = action.payload.service_data.location.title;
		state.single_service_page.service_data.service_category = action.payload.service_data.service_category;
		state.single_service_page.service_data.service_type = action.payload.service_data.service_type;
		state.single_service_page.service_data.service_subtype = action.payload.service_data.service_subtype;
		state.single_service_page.service_data.other_service_subtype = action.payload.service_data.other_service_subtype;
		state.single_service_page.service_data.tags = action.payload.service_data.tags;
		state.single_service_page.service_data.price.min = action.payload.service_data.price.min;
		state.single_service_page.service_data.price.max = action.payload.service_data.price.max;
		state.single_service_page.service_data.price.currency = action.payload.service_data.price.currency;
		state.single_service_page.service_data.price.show_price = action.payload.service_data.price.show_price;
		state.single_service_page.service_data.status = action.payload.service_data.status;
		state.single_service_page.service_data.assigned_to = action.payload.service_data.assigned_to;
		state.service_loading.serviceDetails = false;
	},
	prepare: (
		payload: {
			service_data: ServiceInterface;
		} | null,
	) => ({
		payload: payload,
	}),
};

export const fetchSingleServiceOverview = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.description = action.payload.service_data.description;
		state.service_loading.singleServiceOverview = false;
	},
	prepare: (
		payload: {
			service_data: ServiceInterface;
		} | null,
	) => ({
		payload: payload,
	}),
};

export const fetchSingleServiceSpecifications = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.service_specifications = action.payload.service_data.service_specifications;
		state.service_loading.singleServiceSpecification = false;
	},
	prepare: (
		payload: {
			service_data: ServiceInterface;
		} | null,
	) => ({
		payload: payload,
	}),
};

export const fetchSingleServiceProvider = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.service_provider = action.payload.service_data.service_provider;
		state.service_loading.singleServiceProvider = false;
	},
	prepare: (
		payload: {
			service_data: ServiceInterface;
		} | null,
	) => ({
		payload: payload,
	}),
};

export const fetchSingleServiceAreasOfOperation = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.areas_of_operation = action.payload.service_data.areas_of_operation;
		state.service_loading.singleServiceAreaofOperation = false;
	},
	prepare: (
		payload: {
			service_data: ServiceInterface;
		} | null,
	) => ({
		payload: payload,
	}),
};

export const fetchSingleServiceMapView = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.search_metrics.location_coordinates =
			action.payload.service_data.search_metrics.location_coordinates;
		state.single_service_page.service_data.location = action.payload.service_data.location;

		state.location = {
			location: [
				action.payload.service_data.search_metrics.location_coordinates[1],
				action.payload.service_data.search_metrics.location_coordinates[0],
			],
		};
		state.service_loading.singleServiceLocationCoordinates = false;
	},
	prepare: (
		payload: {
			service_data: ServiceInterface;
		} | null,
	) => ({
		payload: payload,
	}),
};

export const fetchSingleServiceReviews = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_reviews = action.payload.service_data;
		state.service_loading.singleServiceReviews = false;
	},
	prepare: (
		payload: {
			service_reviews:
				| Array<{
						name: string;
						reviewer_id: ObjectId | string;
						profile_picture: any;
						rating: number;
						body: string;
				  }>
				| [];
		} | null,
	) => ({
		payload: payload,
	}),
};

export const fetchSingleServiceCreatorDetails = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.created_by = action.payload.service_data.created_by;
		state.service_loading.singleServiceCreator = false;
	},
	prepare: (
		payload: {
			service_data: ServiceInterface;
		} | null,
	) => ({
		payload: payload,
	}),
};

export const fetchSingleServiceTopServices = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.hotServices = action.payload.top_services;
		state.service_loading.singleServiceHotServices = false;
	},
	prepare: (
		payload: {
			top_services: Array<ServiceInterface>;
		} | null,
	) => ({
		payload: payload,
	}),
};

export const fetchSingleServiceTrendingServices = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.trendingServices = action.payload.trending_services;
		state.service_loading.singleServiceTrendingServices = false;
	},
	prepare: (
		payload: {
			trending_services: Array<ServiceInterface>;
		} | null,
	) => ({
		payload: payload,
	}),
};

export const setSingleServiceCategory = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.service_category = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

export const setSingleServiceType = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.service_type = action.payload;
	},
	prepare: (payload: Array<string>) => ({
		payload: payload,
	}),
};

export const setSingleServiceSubtype = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.service_subtype = action.payload;
	},
	prepare: (payload: Array<string>) => ({
		payload: payload,
	}),
};

export const setSingleServiceOtherSubtype = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.other_service_subtype = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

export const setSingleServiceTags = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.tags = action.payload;
	},
	prepare: (payload: Array<string>) => ({
		payload: payload,
	}),
};

export const setSingleServiceShowPrice = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.price.show_price = action.payload;
	},
	prepare: (payload: boolean) => ({
		payload: payload,
	}),
};

export const setSingleServiceMinPrice = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.price.min = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setSingleServiceMaxPrice = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.price.max = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setSingleServiceOverview = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.description = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

export const setSingleServiceSpecification = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.service_specifications = action.payload;
	},
	prepare: (payload: Array<string>) => ({
		payload: payload,
	}),
};

export const setSingleServiceProviderName = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.service_provider.name = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

export const setSingleServiceProviderEstablishedYear = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.service_provider.year_established = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setSingleServiceProviderDescription = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.service_provider.description = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

export const setSingleServiceAreasOfOperation = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.areas_of_operation = action.payload;
	},
	prepare: (
		payload:
			| Array<{
					title: string;
					pincode: string;
					location_coordinates: Array<number>;
			  }>
			| [],
	) => ({
		payload: payload,
	}),
};

export const setSingleServiceLocationTitle = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.location.title = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

export const setSingleServiceLocationPincode = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.location.pincode = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

export const setSingleServiceLocationCoordinates = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.search_metrics.location_coordinates = action.payload;
	},
	prepare: (payload: Array<number>) => ({
		payload: payload,
	}),
};

export const setSectionToEdit = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.section_to_edit = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

export const setDataBeforeEdit = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.data_before_edit = action.payload;
	},
	prepare: (payload: any) => ({
		payload: payload,
	}),
};

export const fetchNeighbourhoodReviewsPageData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		// state.neighbourhood_reviews_page.location = action.payload.location;
		// state.neighbourhood_reviews_page.reviews = action.payload.reviews;
		state.neighbourhood_reviews_page = action.payload;
	},
	prepare: (payload: StateInterface["neighbourhood_reviews_page"]) => ({
		payload: payload,
	}),
};

export const fetchServiceReviewsPageData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.service_reviews_page = action.payload;
	},
	prepare: (payload: StateInterface["service_reviews_page"]) => ({
		payload: payload,
	}),
};

export const fetchHomePageData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.homePage = action.payload;
		state.single_user_profile = action.payload.user_profile;
		state.single_business_profile = action.payload.business_profile;
	},
	prepare: (payload: StateInterface["homePage"]) => ({
		payload: payload,
	}),
};

export const fetchNotificationPage = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.notifications_page = action.payload;
		state.notifications_loading.notifications = false;
	},
	prepare: (payload: StateInterface["notifications_page"]) => ({
		payload: payload,
	}),
};

export const loadMoreNotificationPage = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.notifications_page.all_notifications.push(...action.payload.payload);
	},
	prepare: (
		payload: {
			status: string;
			payload: any;
			missingFields: Array<string> | null;
			error: string | null;
		} | null,
	) => ({
		payload: payload,
	}),
};

export const fetchRealtimeNotification = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.notifications_page.all_notifications.unshift(...action.payload.all_notifications);
		state.notifications_page.unread_notifications_count = action.payload.unread_notifications_count;
		state.notifications_page.total_notifications_count = action.payload.total_notifications_count;
	},
	prepare: (payload: StateInterface["notifications_page"]) => ({
		payload: payload,
	}),
};

export const updateNotificationViewed = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.notifications_page.all_notifications.map((notification) => {
			if (notification._id === action.payload.payload.notification_id) {
				notification.viewed = true;
			}
		});
		state.notifications_page.unread_notifications_count = action.payload.payload.unread_notifications_count;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload,
	}),
};

export const fetchFaqs = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.faqs_page = action.payload;
		state.faqs_page.search_results = action.payload.faqs;
	},
	prepare: (payload: StateInterface["faqs_page"]) => ({
		payload: payload,
	}),
};

export const setFaqSearchResults = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.faqs_page.search_results = action.payload;
	},
	prepare: (payload: Array<StateInterface["faq"]>) => ({
		payload: payload,
	}),
};

export const fetchSingleFaq = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_faq_page = action.payload;
	},
	prepare: (payload: StateInterface["single_faq_page"]) => ({
		payload: payload,
	}),
};

export const FaqAddUpvote = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_faq_page.faq = action.payload;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const FaqAddDownvote = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_faq_page.faq = action.payload;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const FaqRemoveUpvote = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_faq_page.faq = action.payload;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const FaqRemoveDownvote = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_faq_page.faq = action.payload;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const addAdminUserDetails = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.admin_user_details = action.payload;
	},
	prepare: (payload: {
		status: string;
		payload: {
			addAdmin: BusinessProfileInterface;
		};
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload.addAdmin.admin_user_details,
	}),
};

export const deleteAdminUserDetails = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.admin_user_details = action.payload;
	},
	prepare: (payload: {
		status: string;
		payload: {
			removeAdmin: BusinessProfileInterface;
		};
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload.removeAdmin.admin_user_details,
	}),
};

export const UpdateBusinessAbout = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.about = action.payload.about;
		state.business_profile_snackbar.update_about = true;
	},
	prepare: (payload: {
		status: string;
		payload: BusinessProfileInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const UpdateBusinessProfileShortDescription = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.about.short_description = action.payload;
	},
	prepare: (short_description: string) => ({
		payload: short_description,
	}),
};
export const UpdateBusinessProfilecountry_code = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.phone.country_code = action.payload;
	},
	prepare: (country_code: string) => ({
		payload: country_code,
	}),
};
export const UpdateBusinessProfilePhone = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.phone.phone_number = action.payload;
	},
	prepare: (phone: string) => ({
		payload: phone,
	}),
};
export const UpdateBusinessProfileEmail = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.email.email = action.payload;
	},
	prepare: (email: string) => ({
		payload: email,
	}),
};
export const UpdateBusinessProfileEstDate = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.about.year_established = action.payload;
	},
	prepare: (estDate: string) => ({
		payload: estDate,
	}),
};
export const UpdateBusinessProfileWebsite = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.about.website_url = action.payload;
	},
	prepare: (Website: string) => ({
		payload: Website,
	}),
};
export const UpdateBusinessProfileOfficeAddresses = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.about.office_address.push(action.payload);
	},
	prepare: (officeaddress: Array<string>) => ({
		payload: officeaddress,
	}),
};
export const UpdateBusinessProfileIndustrytype = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.industry_type = action.payload;
	},
	prepare: (industrytype: string) => ({
		payload: industrytype,
	}),
};

export const UpdateBusinessBrokerage = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.agent_brokerage = action.payload.agent_brokerage;
		state.business_profile_snackbar.brokerages = true;
	},
	prepare: (payload: {
		status: string;
		payload: BusinessProfileInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const UpdateBusinessExpertise = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.expertise = action.payload.expertise;
		state.business_profile_snackbar.experties = true;
	},
	prepare: (payload: {
		status: string;
		payload: BusinessProfileInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const UpdateBusinessLanguages = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.languages = action.payload.languages;
		state.business_profile_snackbar.language = true;
	},
	prepare: (payload: {
		status: string;
		payload: BusinessProfileInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const UpdateBusinessServices = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.services_offered = action.payload.services_offered;
		state.business_profile_snackbar.service_offered = true;
	},
	prepare: (payload: {
		status: string;
		payload: BusinessProfileInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const UpdateBusinessWhyChooseUs = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.why_choose_us = action.payload.why_choose_us;
		state.business_profile_snackbar.why_choose_us = true;
	},
	prepare: (payload: {
		status: string;
		payload: BusinessProfileInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const UpdateBusinessFAQs = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.faqs = action.payload.faqs;
		state.business_profile_snackbar.business_faq = true;
	},
	prepare: (payload: {
		status: string;
		payload: BusinessProfileInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const UpdateBusinessHiring = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.hiring = action.payload.hiring;
		state.business_profile_snackbar.job_openings = true;
	},
	prepare: (payload: {
		status: string;
		payload: BusinessProfileInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const AddSepcialOfferDetailsForBusinessProfile = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile = action.payload;
	},
	prepare: (payload: {
		status: string;
		payload: BusinessProfileInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const UpdateBusinessHonors = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.awards_and_honors = action.payload.awards_and_honors;
		state.business_profile_snackbar.honors_and_awards = true;
	},
	prepare: (payload: {
		status: string;
		payload: BusinessProfileInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const UpdateBusinessLicences = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.licences_and_certifications =
			action.payload.licences_and_certifications;
		state.business_profile_snackbar.licences_and_certificates = true;
	},
	prepare: (payload: {
		status: string;
		payload: BusinessProfileInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const SetDefaultLocation = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.location = action.payload;
	},
	prepare: (payload: StateInterface["location"]) => ({
		payload: payload,
	}),
};
export const UpdateUserAboutDetails = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.user_profile.first_name = action.payload.first_name;
		state.single_user_profile.user_profile.last_name = action.payload.last_name;
		state.single_user_profile.user_profile.about.short_description = action.payload.about.short_description;
		state.single_user_profile.user_profile.recovery_email.email = action.payload.recovery_email.email;
		state.single_user_profile.user_profile.phone = action.payload.phone;
		state.single_user_profile.user_profile.country_code = action.payload.country_code;
		state.single_user_profile.user_profile.date_of_birth = action.payload.date_of_birth;
		state.user_profile_snackbar.update_about = true;
	},
	prepare: (payload: {
		status: string;
		payload: UserInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};
export const UpdateUserSkills = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.user_profile = action.payload;
	},
	prepare: (payload: {
		status: string;
		payload: UserInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};
export const AddUserCourses = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.user_profile = action.payload;
	},
	prepare: (payload: {
		status: string;
		payload: UserInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};
export const addExperienceForUser = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.user_profile = action.payload;
	},
	prepare: (payload: {
		status: string;
		payload: UserInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};
export const AddUserLanguage = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.user_profile = action.payload;
	},
	prepare: (payload: {
		status: string;
		payload: UserInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const AddHonorsAndAwardsDetailsForUser = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.user_profile = action.payload;
	},
	prepare: (payload: {
		status: string;
		payload: BusinessProfileInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const AddLicenseAndCertificationDetailsForUser = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.user_profile = action.payload;
	},
	prepare: (payload: {
		status: string;
		payload: BusinessProfileInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const AddSaveEntityBusinessProfile = {
	reducer: (state: StateInterface, action: AnyAction) => {},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const AddSaveEntityUserProfile = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.user_profile = action.payload;
	},
	prepare: (payload: {
		status: string;
		payload: UserInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const UpdateServiceDetails = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.service_category = action.payload.payload.service_category;
		state.single_service_page.service_data.service_type = action.payload.payload.service_type;
		state.single_service_page.service_data.service_subtype = action.payload.payload.service_subtype;
		state.single_service_page.service_data.tags = action.payload.payload.tags;
		state.single_service_page.service_data.price = action.payload.payload.price;
		state.single_service_page.service_data.published = action.payload.payload.published;
		state.single_service_page.service_data.status = action.payload.payload.status;
		state.service_snackbar.service_details = true;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload,
	}),
};

export const UpdateServiceOverView = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.description = action.payload.payload.overview;
		state.single_service_page.service_data.published = action.payload.payload.published;
		state.single_service_page.service_data.status = action.payload.payload.status;
		state.service_snackbar.service_overview = true;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload,
	}),
};

export const UpdateServiceSpecification = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.service_specifications = action.payload.payload.service_specifications;
		state.single_service_page.service_data.published = action.payload.payload.published;
		state.single_service_page.service_data.status = action.payload.payload.status;
		state.service_snackbar.service_specification = true;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload,
	}),
};

export const UpdateServiceProvider = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.service_provider = action.payload.payload.service_provider;
		state.single_service_page.service_data.published = action.payload.payload.published;
		state.single_service_page.service_data.status = action.payload.payload.status;
		state.service_snackbar.about_service_provider = true;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload,
	}),
};

export const UpdateServiceAreasOfOperation = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.areas_of_operation = action.payload.payload.areas_of_operation;
		state.single_service_page.service_data.published = action.payload.payload.published;
		state.single_service_page.service_data.status = action.payload.payload.status;
		state.service_snackbar.areas_of_operation = true;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload,
	}),
};

export const updateServiceLocation = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.location.title = action.payload.payload.location_title;
		state.single_service_page.service_data.location.pincode = action.payload.payload.location_pincode;
		state.single_service_page.service_data.search_metrics.location_coordinates =
			action.payload.payload.location_coordinates;
		state.single_service_page.service_data.published = action.payload.payload.published;
		state.single_service_page.service_data.status = action.payload.payload.status;
		state.service_snackbar.service_map = true;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload,
	}),
};

export const followUser = {
	reducer: (state: StateInterface, action: AnyAction) => {
		if (action.payload.is_business_profile) {
			state.single_business_profile.business_profile.followers = action.payload.addFollower;
			// state.single_business_profile.business_profile.following =
			// action.payload.payload.addFollowing;
		} else {
			state.single_user_profile.user_profile.followers = action.payload.addFollower;
			// state.single_user_profile.user_profile.following =
			// action.payload.payload.addFollowing;
		}
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const unfollowUser = {
	reducer: (state: StateInterface, action: AnyAction) => {
		if (action.payload.is_business_profile) {
			state.single_business_profile.business_profile.followers = action.payload.removefollower;
			// state.single_business_profile.business_profile.following =
			//   action.payload.payload.removefollowing;
		} else {
			state.single_user_profile.user_profile.followers = action.payload.removefollower;
			// state.single_user_profile.user_profile.following =
			//   action.payload.payload.removefollowing;
		}
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const UpdatePropertyDetails = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.transaction_type = action.payload.payload.transaction_type;
		state.single_property_page.property.property_category = action.payload.payload.property_category;
		state.single_property_page.property.property_type = action.payload.payload.property_type;
		state.single_property_page.property.property_subtype = action.payload.payload.property_subtype;
		state.single_property_page.property.price = action.payload.payload.price;
		state.single_property_page.property.security_deposit = action.payload.payload.security_deposit;
		state.single_property_page.property.possession_date = action.payload.payload.possession_date;
		state.single_property_page.property.property_age = action.payload.payload.property_age;
		state.single_property_page.property.availability = action.payload.payload.availability;
		state.single_property_page.property.unit_facing = action.payload.payload.unit_facing;
		state.single_property_page.property.land_facing = action.payload.payload.land_facing;
		state.single_property_page.property.tags = action.payload.payload.tags;
		state.single_property_page.property.status = action.payload.payload.status;
		state.single_property_page.property.published = action.payload.payload.published;
		state.property_snackbar.property_details = true;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload,
	}),
};

export const UpdateConfigAndArea = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.area = action.payload.payload.area;
		state.single_property_page.property.plot_dimensions = action.payload.payload.plot_dimensions.map(
			(plot_dimension: string) =>
				plot_dimension.replace(/_/g, " ").charAt(0).toUpperCase() + plot_dimension.replace(/_/g, " ").slice(1),
		);
		state.single_property_page.property.other_plot_dimensions = action.payload.payload.other_plot_dimensions;
		state.single_property_page.property.furnished = action.payload.payload.furnished;
		state.single_property_page.property.bhk = action.payload.payload.bhk;
		state.single_property_page.property.bathroom = action.payload.payload.bathroom;
		state.single_property_page.property.balconies = action.payload.payload.balconies;
		state.single_property_page.property.floors = action.payload.payload.floors;
		state.single_property_page.property.floor_no = action.payload.payload.floor_no;
		state.single_property_page.property.no_of_units = action.payload.payload.no_of_units;
		state.single_property_page.property.no_of_seats = action.payload.payload.no_of_seats;
		state.single_property_page.property.published = action.payload.payload.published;
		state.single_property_page.property.status = action.payload.payload.status;
		state.property_snackbar.property_config = true;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload,
	}),
};

export const UpdateAboutDeveloperProperty = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.builder.name = action.payload.payload.developer_name;
		state.single_property_page.property.builder.description = action.payload.payload.developer_description;
		state.single_property_page.property.builder.year_est = action.payload.payload.developer_year_est;
		state.single_property_page.property.builder.ongoing_projects = action.payload.payload.developer_ongoing_projects;
		state.single_property_page.property.builder.completed_projects =
			action.payload.payload.developer_completed_projects;
		state.single_property_page.property.builder.upcoming_projects = action.payload.payload.developer_upcoming_projects;
		state.single_property_page.property.builder.total_projects = action.payload.payload.developer_total_projects;
		state.single_property_page.property.published = action.payload.payload.published;
		state.single_property_page.property.status = action.payload.payload.status;
		state.property_snackbar.property_developer = true;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload,
	}),
};

export const updateLocationProperty = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.location.title = action.payload.payload.location_title;
		state.single_property_page.property.location.pincode = action.payload.payload.location_pincode;
		state.single_property_page.property.search_metrics.location_coordinates =
			action.payload.payload.location_coordinates;
		state.single_property_page.property.published = action.payload.payload.published;
		state.single_property_page.property.status = action.payload.payload.status;
		state.property_snackbar.property_location_coordinates = true;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload,
	}),
};

export const UpdateAboutLocationProperty = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.location.about_location = action.payload.payload.about_location;
		state.single_property_page.property.published = action.payload.payload.published;
		state.single_property_page.property.status = action.payload.payload.status;
		state.property_snackbar.property_about_location = true;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload,
	}),
};

export const UpdateAboutProperty = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.about_property = action.payload.payload.about_property;
		state.single_property_page.property.published = action.payload.payload.published;
		state.single_property_page.property.status = action.payload.payload.status;
		state.property_snackbar.about_property = true;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload,
	}),
};

export const UpdateAmenities = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.amenities = action.payload.payload.amenities;
		state.single_property_page.property.status = action.payload.payload.status;
		state.property_snackbar.property_amenities = true;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload,
	}),
};

export const UpdateOverView = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.description = action.payload.payload.overview;
		state.single_property_page.property.published = action.payload.payload.published;
		state.single_property_page.property.status = action.payload.payload.status;
		state.property_snackbar.property_overview = true;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload,
	}),
};

export const UpdateAiOverView = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.description = action.payload.overview;
		state.single_property_page.property.published = action.payload.published;
		state.single_property_page.property.status = action.payload.status;
		state.property_snackbar.property_overview = true;
		state.single_property_page.property.is_overview_ai_generated = action.payload.is_overview_ai_generated;
		state.single_property_page.property.manual_overview = action.payload.manual_overview;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload,
	}),
};

export const changeDataTuning = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.use_data_for_fine_tuning = action.payload;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const UpdateVicinity = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.vicinity = action.payload.payload.vicinity;
		state.single_property_page.property.status = action.payload.payload.status;
		state.property_snackbar.property_vicinity = true;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload,
	}),
};

export const setDrawerScreen = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.chat_states.drawer_screen = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

export const setCreateBoost = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.boost = action.payload;
	},
	prepare: (payload: StateInterface["boost"]) => ({ payload: payload }),
};

export const setStartBoost = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.boost = action.payload;
		state.boost_msg = true;
	},
	prepare: (payload: {
		status: string;
		payload: BoostInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const EmployeeRequest = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.employees = action.payload.employees;
		state.single_business_profile.business_profile.employee_details = action.payload.employee_details;
	},
	prepare: (payload: {
		status: string;
		payload: {
			newEmployeeRequest: BusinessProfileInterface;
		};
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload.newEmployeeRequest,
	}),
};
export const acceptEmployeeRequest = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile = action.payload;
	},
	prepare: (payload: {
		status: string;
		payload: BusinessProfileInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};
export const deleteEmployeeRequest = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile = action.payload;
	},
	prepare: (payload: {
		status: string;
		payload: BusinessProfileInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const updateBoost = {
	reducer: (state: StateInterface, action: AnyAction) => {
		if (action.payload.status === "success") {
			state.boost_msg = true;
			state.boost = action.payload.payload;
		} else {
			state.boost_error = action.error;
			state.helper_text = action.error;
		}
	},

	prepare: (payload: {
		status: string;
		payload: BoostInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => {
		return {
			payload: payload,
			error: payload.error,
		};
	},
};

export const cancelBoost = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.boost = action.payload;
		state.boost_msg = true;
	},
	prepare: (payload: {
		status: string;
		payload: BoostInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const SetDefaultLookingFor = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.looking_for = action.payload;
	},
	prepare: (payload: StateInterface["looking_for"]) => ({
		payload: payload,
	}),
};
export const SetIsStudio = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.is_studio = action.payload;
	},
	prepare: (payload: StateInterface["is_studio"]) => ({
		payload: payload,
	}),
};

export const SetDefaultLocationBoost = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.location_boost = action.payload;
	},
	prepare: (payload: StateInterface["location_boost"]) => ({
		payload: payload,
	}),
};

export const fetchReceiptPageData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.receipt_page = action.payload;
	},
	prepare: (payload: StateInterface["receipt_page"]) => ({
		payload: payload,
	}),
};

export const fetchAccountSettingsPageData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.account_settings_page = action.payload;
	},
	prepare: (payload: StateInterface["account_settings_page"]) => ({
		payload: payload,
	}),
};

export const fetchAccountSettingsProfileKycInfo = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.account_settings_page.user_data.kyc_info = action.payload.kyc_data;
	},
	prepare: (payload: { kyc_data: KYCInformationBusinessProfile | null }) => ({
		payload: payload,
	}),
};

export const addPropertyImages = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.images = action.payload.payload.images;
		state.single_property_page.property.published = action.payload.payload.published;
		state.single_property_page.property.status = action.payload.payload.status;
		state.property_snackbar.property_images_upload = true;
	},
	prepare: (payload: StateInterface["single_property_page"]["property"]["images"]) => ({
		payload: payload,
	}),
};

export const createNewProperty = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.properties = action.payload;
		state.new_listing_url = action.payload[0]?.slug;
	},
	prepare: (payload: {
		status: string;
		payload: PropertyInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const createNewPropertyWithUploadTool = {
	reducer: (state: StateInterface, action: AnyAction) => {
		// {
		// 	action.payload.propertyData[0].transaction_type === "ofb"
		// 		? (state.new_property_data.snack_ofb_value = true)
		// 		: (state.new_property_data.snack_property_value = true);
		// }
		// state.new_property_data.snack_text =
		// 	action.payload.propertyData.length > 0
		// 		? action.payload.propertyData[0].transaction_type === "ofb"
		// 			? "OFB Created Successfully"
		// 			: "Property Created Successfully"
		// 		: action.payload.propertyData[0].transaction_type === "ofb"
		// 			? "Error while creating OFB"
		// 			: "Error while creating Property";
		{
			action.payload.propertyData[0].transaction_type === "ofb"
				? ((state.single_business_profile.ofb_properties = action.payload.propertyData),
					(state.new_property_data.snack_ofb_value = true),
					(state.new_property_data.snack_text =
						action.payload.propertyData.length > 0 ? "OFB Created Successfully" : "Error while creating OFB"))
				: ((state.single_business_profile.properties = action.payload.propertyData),
					(state.new_property_data.snack_property_value = true),
					(state.new_property_data.snack_text =
						action.payload.propertyData.length > 0
							? "Property Created Successfully"
							: "Error while creating Property"));
		}
	},
	prepare: (payload: {
		status: string;
		payload: PropertyInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const createNewServiceWithUploadTool = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_service_data.snack_value = true;
		state.new_service_data.snack_text =
			action.payload.serviceData.length > 0 ? "Service Created Successfully" : "Error while creating service";
		state.single_business_profile.services = action.payload.serviceData;
		state.new_listing_url = action.payload.business_profile_slug;
	},
	prepare: (payload: {
		status: string;
		payload: ServiceInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const AllowcopyProperty = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.allow_copy = action.payload.allow_copy;
	},
	prepare: (payload: {
		status: string;
		payload: PropertyInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const ShowContactDetailsProperty = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.show_contact_details = action.payload.show_contact_details;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const ShowContactDetailsService = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.show_contact_details = action.payload.show_contact_details;
	},
	prepare: (payload: {
		status: string;
		payload: PropertyInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const CreateNewService = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.services = action.payload;
		state.new_listing_url = action.payload[0].slug;
	},
	prepare: (payload: {
		status: string;
		payload: ServiceInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const createNewOFBProperty = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.ofb_properties = action.payload;
		state.new_listing_url = action.payload[0].slug;
	},
	prepare: (payload: {
		status: string;
		payload: PropertyInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const updatePropertyImage = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.images = action.payload.payload.images;
		state.single_property_page.property.published = action.payload.payload.published;
		state.single_property_page.property.status = action.payload.payload.status;
		state.property_snackbar.property_images_edit = true;
	},
	prepare: (payload: StateInterface["single_property_page"]["property"]["images"]) => ({
		payload: payload,
	}),
};

export const cropPropertyImage = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.images = action.payload.payload.images;
		state.single_property_page.property.published = action.payload.payload.published;
		state.single_property_page.property.status = action.payload.payload.status;
		state.property_snackbar.property_images_edit = true;
	},
	prepare: (payload: StateInterface["single_property_page"]["property"]["images"]) => ({
		payload: payload,
	}),
};

export const deletePropertyImage = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.images = action.payload.payload.images;
		state.single_property_page.property.published = action.payload.payload.published;
		state.single_property_page.property.status = action.payload.payload.status;
		state.property_snackbar.property_images_delete = true;
	},
	prepare: (payload: StateInterface["single_property_page"]["property"]["images"]) => ({
		payload: payload,
	}),
};

export const deleteServiceImage = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.images = action.payload.payload.images;
		state.single_service_page.service_data.published = action.payload.payload.published;
		state.single_service_page.service_data.status = action.payload.payload.status;
		state.service_snackbar.service_images_delete = true;
	},
	prepare: (payload: StateInterface["single_service_page"]["service_data"]["images"]) => ({
		payload: payload,
	}),
};

export const deleteBusinessProfilePicture = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.display_picture_url = action.payload.payload;
		state.profile_context.business_profile_image.exists = false;
		state.business_profile_snackbar.delete_profile_picture = true;
	},
	prepare: (payload: StateInterface["single_business_profile"]["business_profile"]["display_picture_url"]) => ({
		payload: payload,
	}),
};

export const deleteBusinessCoverImage = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.cover_image = action.payload.payload;
		state.business_profile_snackbar.delete_cover_image = true;
	},
	prepare: (payload: StateInterface["single_business_profile"]["business_profile"]["cover_image"]) => ({
		payload: payload,
	}),
};

export const deleteUserProfilePicture = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.user_profile.profile_picture = action.payload.payload;
		state.account_settings_page.user_data.profile_picture = action.payload.payload;
		state.user_profile_snackbar.delete_profile_picture = true;
	},
	prepare: (payload: StateInterface["single_user_profile"]["user_profile"]["profile_picture"]) => ({
		payload: payload,
	}),
};

export const deleteUserCoverImage = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.user_profile.cover_image = action.payload.payload;
	},
	prepare: (payload: StateInterface["single_user_profile"]["user_profile"]["cover_image"]) => ({
		payload: payload,
	}),
};

export const CreateNewSubscription = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.account_settings_page.subscriptions.push(action.payload.create_new_subscription);
		state.account_settings_page.user_data.beegru_points = action.payload.debit_beegru_points.beegru_points;
		state.account_settings_page.user_beegru_points_transactions.push(action.payload.store_beegru_points_transaction);
		state.accountsetting_snackbar.subscription_snackbar = true;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const RenewSubscription = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.account_settings_page.subscriptions.filter(
			(subscription: any) => subscription._id === action.payload.renew_subscription._id,
		)[0] = action.payload.renew_subscription;
		state.account_settings_page.user_data.beegru_points = action.payload.debit_beegru_points.beegru_points;
		state.account_settings_page.user_beegru_points_transactions.push(action.payload.store_beegru_points_transaction);
		state.accountsetting_snackbar.subscription_snackbar = true;
	},
	prepare: (payload: {
		status: string;
		payload: StateInterface["subscriptions"];
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const CancelSubscription = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.account_settings_page.subscriptions.push(action.payload.cancel_subscription);
		state.accountsetting_snackbar.cancel_subscription_snackbar = true;
	},
	prepare: (payload: {
		status: string;
		payload: StateInterface["subscriptions"];
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const ProfileToUpgrade = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.profile_to_upgrade = action.payload;
	},
	prepare: (payload: StateInterface["profile_to_upgrade"]) => ({
		payload: payload,
	}),
};

export const ProfileToUpgradeId = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.profile_to_upgrade_id = action.payload;
	},
	prepare: (payload: StateInterface["profile_to_upgrade_id"]) => ({
		payload: payload,
	}),
};

export const ProfileToUpgradeType = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.profile_to_upgrade_type = action.payload;
	},
	prepare: (payload: StateInterface["profile_to_upgrade_type"]) => ({
		payload: payload,
	}),
};

export const deletePropertyDetails = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.properties = action.payload.payload.properties;
		state.single_business_profile.propCount = action.payload.payload.propertiesCount;
		state.business_profile_snackbar.delete_property_listing = true;
	},
	prepare: (payload: any) => ({
		payload: payload,
	}),
};

export const updatePersonalInfo = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.account_settings_page.user_data.first_name = action.payload.first_name;
		state.account_settings_page.user_data.last_name = action.payload.last_name;
		state.account_settings_page.user_data.work_experience = action.payload.work_experience;
		state.account_settings_page.user_data.recovery_email.email = action.payload.recovery_email.email;
		state.account_settings_page.user_data.address.line1 = action.payload.address.line1;
		state.account_settings_page.user_data.address.line2 = action.payload.address.line2;
		state.account_settings_page.user_data.address.city = action.payload.address.city;
		state.account_settings_page.user_data.address.state = action.payload.address.state;
		state.account_settings_page.user_data.address.pincode = action.payload.address.pincode;
		state.account_settings_page.user_data.address.country = action.payload.address.country;
		state.accountsetting_snackbar.personalinfo_snackbar = true;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const updateUserKycInfo = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.account_settings_page.user_data.kyc_info = action.payload.payload;
		state.accountsetting_snackbar.kyc_snackbar = true;
	},
	prepare: (payload: StateInterface["user"]["kyc_info"]) => ({
		payload: payload,
	}),
};

export const updateBusinessProfileKycInfo = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.account_settings_page.user_data.kyc_info = action.payload.payload;
		state.accountsetting_snackbar.kyc_snackbar = true;
	},
	prepare: (payload: StateInterface["user"]["kyc_info"]) => ({
		payload: payload,
	}),
};

export const addProfilePicture = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.account_settings_page.user_data.profile_picture = action.payload.profile_picture;
		state.single_user_profile.user_profile.profile_picture = action.payload.profile_picture;
		if (action.payload.snack_value === "upload_profile_picture") {
			state.user_profile_snackbar.upload_profile_picture = true;
		} else if (action.payload.snack_value === "edit_profile_picture") {
			state.user_profile_snackbar.edit_profile_picture = true;
		} else if (action.payload.snack_value === "corrupt_file") {
			state.user_profile_snackbar.corrupt_file = true;
		}
	},
	prepare: (payload: StateInterface["user"]["profile_picture"]) => ({
		payload: payload,
	}),
};

export const addCoverImage = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.user_profile.cover_image = action.payload.cover_image;
		if (action.payload.snack_value === "upload_cover_image") {
			state.user_profile_snackbar.upload_cover_image = true;
		} else if (action.payload.snack_value === "edit_cover_image") {
			state.user_profile_snackbar.edit_cover_image = true;
		} else if (action.payload.snack_value === "corrupt_file") {
			state.user_profile_snackbar.corrupt_file = true;
		}
	},
	prepare: (payload: StateInterface["single_user_profile"]["user_profile"]["cover_image"]) => ({
		payload: payload,
	}),
};

export const addBusinessProfilePicture = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.display_picture_url = action.payload.profile_picture;
		state.profile_context.business_profile_image.exists = action.payload.profile_picture.exists;
		state.profile_context.business_profile_image.file_id = action.payload.profile_picture.file_id;
		if (action.payload.snack_value === "upload_profile_picture") {
			state.business_profile_snackbar.upload_profile_picture = true;
		} else if (action.payload.snack_value === "edit_profile_picture") {
			state.business_profile_snackbar.edit_profile_picture = true;
		}
	},
	prepare: (payload: StateInterface["business_profile"]["display_picture_url"]) => ({
		payload: payload,
	}),
};

export const addBusinessProfileCoverImage = {
	reducer: (state: StateInterface, action: AnyAction) => {
		console.log("action", action);
		state.single_business_profile.business_profile.cover_image = action.payload.cover_image;
		if (action.payload.snack_value === "upload_cover_image") {
			state.business_profile_snackbar.upload_cover_image = true;
		} else if (action.payload.snack_value === "edit_cover_image") {
			state.business_profile_snackbar.edit_cover_image = true;
		}
	},
	prepare: (payload: StateInterface["business_profile"]["cover_image"]) => ({
		payload: payload,
	}),
};

export const addServiceImages = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.images = action.payload.payload.images;
		state.single_service_page.service_data.published = action.payload.payload.published;
		state.single_service_page.service_data.status = action.payload.payload.status;
		state.service_snackbar.service_images_upload = true;
	},
	prepare: (payload: StateInterface["single_service_page"]["service_data"]["images"]) => ({
		payload: payload,
	}),
};

export const updateServiceImage = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.images = action.payload.payload.images;
		state.single_service_page.service_data.published = action.payload.payload.published;
		state.single_service_page.service_data.status = action.payload.payload.status;
		state.service_snackbar.service_images_edit = true;
	},
	prepare: (payload: StateInterface["single_service_page"]["service_data"]["images"]) => ({
		payload: payload,
	}),
};

export const cropServiceImage = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.images = action.payload.payload;
		state.service_snackbar.service_images_edit = true;
	},
	prepare: (payload: StateInterface["single_service_page"]["service_data"]["images"]) => ({
		payload: payload,
	}),
};

export const createNewBusinessProfile = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.profile_context.kyc_info = {
			kyc_status: action.payload.business_profile_kyc_status,
		};
		state.new_business_profile_url = action.payload.business_profile_slug;
		state.profile_context.is_business_profile = true;
		state.profile_context.business_profile_id = action.payload.business_profile_id;
		state.profile_context.business_profile_image = action.payload.business_profile_image;
		state.profile_context.page_type = action.payload.business_profile_type;
		state.profile_context.page_slug = action.payload.business_profile_slug;
		state.profile_context.page_title = action.payload.business_profile_title;
		// state.profile_context.kyc_info.kyc_status = action.payload.business_profile_kyc_status;
		state.profile_context.subscription_information = action.payload.business_profile_subscription_information;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const deleteBusinessProfile = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.business_profile = action.payload.payload;
		state.business_profile_snackbar.delete_business_profile = true;
	},
	prepare: (payload: StateInterface["business_profile"]) => ({
		payload: payload,
	}),
};

export const deletePropertyOFBDetails = {
	reducer: (state: StateInterface, action: AnyAction) => {
		if (action.payload.payload.url.includes("users")) {
			state.single_user_profile.ofb_properties = action.payload.payload.properties;
			state.single_user_profile.propertiesCount = action.payload.payload.ofbCount;
			state.business_profile_snackbar.delete_property_listing = true;
		} else {
			state.single_business_profile.ofb_properties = action.payload.payload.properties;
			state.single_business_profile.ofbpropertiesCount = action.payload.payload.ofbCount;
			state.business_profile_snackbar.delete_property_listing = true;
		}
	},
	prepare: (payload: any) => ({
		payload: payload,
	}),
};
export const deletePropertyOFBUsers = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.ofb_properties = action.payload;
	},
	prepare: (payload: {
		status: string;
		payload: PropertyInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const createNewOFBPropertyUser = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.ofb_properties = action.payload;
		state.new_listing_url = action.payload[0].slug;
	},
	prepare: (payload: {
		status: string;
		payload: PropertyInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};
export const updateBooms = {
	reducer: (state: StateInterface, action: AnyAction) => {
		if (action.payload.payload.is_business_profile) {
			if (action.payload.tabValue === 0) {
				const postSwrIndex: number = state.single_business_profile.posts.findIndex(
					(post: PostInterface) => post._id === action.payload.payload[0]._id,
				);
				state.single_business_profile.posts[postSwrIndex].booms = action.payload.payload[0].booms;
			} else {
				if (action.payload.tabValue === 1) {
					state.single_post_page.post.booms = action.payload.payload[0].booms;
				} else {
					if (action.payload.payload.url.includes("users")) {
						const postSavedIndex: number = state.single_user_profile.saved_items.posts.findIndex(
							(post: PostInterface) => post._id === action.payload.payload[0]._id,
						);
						state.single_user_profile.saved_items.posts[postSavedIndex].booms = action.payload.payload[0].booms;
					} else {
						const postSavedIndex: number = state.single_business_profile.saved_items.posts.findIndex(
							(post: PostInterface) => post._id === action.payload.payload[0]._id,
						);
						state.single_business_profile.saved_items.posts[postSavedIndex].booms = action.payload.payload[0].booms;
					}
				}
			}
		} else {
			if (action.payload.tabValue === 0) {
				const postSwrIndex: number = state.single_user_profile.posts.findIndex(
					(post: PostInterface) => post._id === action.payload.payload[0]._id,
				);
				state.single_user_profile.posts[postSwrIndex].booms = action.payload.payload[0].booms;
			} else {
				if (action.payload.tabValue === 1) {
					state.single_post_page.post.booms = action.payload.payload[0].booms;
				} else {
					if (action.payload.payload.url.includes("users")) {
						const postSavedIndex: number = state.single_user_profile.saved_items.posts.findIndex(
							(post: PostInterface) => post._id === action.payload.payload[0]._id,
						);
						state.single_user_profile.saved_items.posts[postSavedIndex].booms = action.payload.payload[0].booms;
					} else {
						const postSavedIndex: number = state.single_business_profile.saved_items.posts.findIndex(
							(post: PostInterface) => post._id === action.payload.payload[0]._id,
						);
						state.single_business_profile.saved_items.posts[postSavedIndex].booms = action.payload.payload[0].booms;
					}
				}
			}
		}
	},
	prepare: (payload: {
		tabValue: number;
		response: {
			status: string;
			payload: any;
			missingFields: Array<string> | null;
			error: string | null;
		};
	}) => ({
		payload: { payload: payload.response.payload, tabValue: payload.tabValue },
	}),
};
export const RemoveBooms = {
	reducer: (state: StateInterface, action: AnyAction) => {
		if (action.payload.payload.is_business_profile) {
			if (action.payload.tabValue === 0) {
				const postSwrIndex: number = state.single_business_profile.posts.findIndex(
					(post: PostInterface) => post._id === action.payload.payload[0]._id,
				);
				state.single_business_profile.posts[postSwrIndex].booms = action.payload.payload[0].booms;
			} else {
				if (action.payload.tabValue === 1) {
					state.single_post_page.post.booms = action.payload.payload[0].booms;
				} else {
					if (action.payload.payload.url.includes("users")) {
						const postSavedIndex: number = state.single_user_profile.saved_items.posts.findIndex(
							(post: PostInterface) => post._id === action.payload.payload[0]._id,
						);
						state.single_user_profile.saved_items.posts[postSavedIndex].booms = action.payload.payload[0].booms;
					} else {
						const postSavedIndex: number = state.single_business_profile.saved_items.posts.findIndex(
							(post: PostInterface) => post._id === action.payload.payload[0]._id,
						);
						state.single_business_profile.saved_items.posts[postSavedIndex].booms = action.payload.payload[0].booms;
					}
				}
			}
		} else {
			if (action.payload.tabValue === 0) {
				const postSwrIndex: number = state.single_user_profile.posts.findIndex(
					(post: PostInterface) => post._id === action.payload.payload[0]._id,
				);
				state.single_user_profile.posts[postSwrIndex].booms = action.payload.payload[0].booms;
			} else {
				if (action.payload.tabValue === 1) {
					state.single_post_page.post.booms = action.payload.payload[0].booms;
				} else {
					if (action.payload.payload.url.includes("users")) {
						const postSavedIndex: number = state.single_user_profile.saved_items.posts.findIndex(
							(post: PostInterface) => post._id === action.payload.payload[0]._id,
						);
						state.single_user_profile.saved_items.posts[postSavedIndex].booms = action.payload.payload[0].booms;
					} else {
						const postSavedIndex: number = state.single_business_profile.saved_items.posts.findIndex(
							(post: PostInterface) => post._id === action.payload.payload[0]._id,
						);
						state.single_business_profile.saved_items.posts[postSavedIndex].booms = action.payload.payload[0].booms;
					}
				}
			}
		}
	},
	prepare: (payload: {
		tabValue: number;
		response: {
			status: string;
			payload: any;
			missingFields: Array<string> | null;
			error: string | null;
		};
	}) => ({
		payload: { payload: payload.response.payload, tabValue: payload.tabValue },
	}),
};
export const updateBlasts = {
	reducer: (state: StateInterface, action: AnyAction) => {
		if (action.payload.payload.is_business_profile) {
			if (action.payload.tabValue === 0) {
				const postSwrIndex: number = state.single_business_profile.posts.findIndex(
					(post: PostInterface) => post._id === action.payload.payload.post[0]._id,
				);
				state.single_business_profile.posts[postSwrIndex].blasts = action.payload.payload.post[0].blasts;
			} else {
				if (action.payload.tabValue === 1) {
					state.single_post_page.post.blasts = action.payload.payload.post[0].blasts;
				} else {
					if (action.payload.payload.url.includes("users")) {
						const postSavedIndex: number = state.single_user_profile.saved_items.posts.findIndex(
							(post: PostInterface) => post._id === action.payload.payload.post[0]._id,
						);
						state.single_user_profile.saved_items.posts[postSavedIndex].blasts = action.payload.payload.post[0].blasts;
					} else {
						const postSwrIndex: number = state.single_business_profile.saved_items.posts.findIndex(
							(post: PostInterface) => post._id === action.payload.payload.post[0]._id,
						);
						state.single_business_profile.saved_items.posts[postSwrIndex].blasts =
							action.payload.payload.post[0].blasts;
					}
				}
			}
		} else {
			if (action.payload.tabValue === 0) {
				const postSwrIndex: number = state.single_user_profile.posts.findIndex(
					(post: PostInterface) => post._id === action.payload.payload.post[0]._id,
				);
				state.single_user_profile.posts[postSwrIndex].blasts = action.payload.payload.post[0].blasts;
			} else {
				if (action.payload.tabValue === 1) {
					state.single_post_page.post.blasts = action.payload.payload.post[0].blasts;
				} else {
					if (action.payload.payload.url.includes("users")) {
						const postSavedIndex: number = state.single_user_profile.saved_items.posts.findIndex(
							(post: PostInterface) => post._id === action.payload.payload.post[0]._id,
						);
						state.single_user_profile.saved_items.posts[postSavedIndex].blasts = action.payload.payload.post[0].blasts;
					} else {
						const postSwrIndex: number = state.single_business_profile.saved_items.posts.findIndex(
							(post: PostInterface) => post._id === action.payload.payload.post[0]._id,
						);
						state.single_business_profile.saved_items.posts[postSwrIndex].blasts =
							action.payload.payload.post[0].blasts;
					}
				}
			}
		}
	},
	prepare: (payload: {
		tabValue: number;
		response: {
			status: string;
			payload: any;
			missingFields: Array<string> | null;
			error: string | null;
		};
	}) => ({
		payload: { payload: payload.response.payload, tabValue: payload.tabValue },
	}),
};

export const CreateNewPostUser = {
	reducer: (state: StateInterface, action: AnyAction) => {
		if (action.payload.type === "reshare") {
		} else {
			if (action.payload.payload.is_business_profile) {
				state.single_business_profile.posts = action.payload.payload.posts;
				state.postSwrForBusiness = action.payload.payload;
			} else {
				state.single_user_profile.posts = action.payload.payload.posts;
				state.postSwrForUser = action.payload.payload;
			}
		}
	},
	prepare: (payload: {
		type: string;
		response: {
			status: string;
			payload: any;
			missingFields: Array<string> | null;
			error: string | null;
		};
	}) => ({
		payload: { payload: payload.response.payload, type: payload.type },
	}),
};
export const CreateNewCommentsUserPost = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.posts.map((post: any) => {
			if (post._id === action.payload._id) {
				post.comments = action.payload.comments;
			}
		});
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => {
		return {
			payload: payload.payload,
		};
	},
};
export const deletePostUser = {
	reducer: (state: StateInterface, action: AnyAction) => {
		if (action.payload.is_business_profile) {
			state.single_business_profile.posts = action.payload.posts;
			state.postSwrForBusiness = action.payload.posts;
		} else {
			state.single_user_profile.posts = action.payload.posts;
			state.postSwrForUser = action.payload.posts ? action.payload.posts : [];
		}
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => {
		return {
			payload: payload.payload,
		};
	},
};
export const ReplyToComment = {
	reducer: (state: StateInterface, action: AnyAction) => {
		if (action.payload.payload.type.includes("post")) {
			state.single_post_page.post.comments = action.payload.payload.comments;
		}
		if (action.payload.payload.is_business_profile) {
			if (action.payload.tabValue === 0) {
				const postSwrIndex: number = state.single_business_profile.posts.findIndex(
					(post: PostInterface) => post._id === action.payload.payload[0]._id,
				);
				state.single_business_profile.posts[postSwrIndex].comments = action.payload.payload[0].comments;
			} else {
				const postSwrIndex: number = state.single_business_profile.saved_items.posts.findIndex(
					(post: PostInterface) => post._id === action.payload.payload[0]._id,
				);
				state.single_business_profile.saved_items.posts[postSwrIndex].comments = action.payload.payload[0].comments;
			}
		} else {
			if (action.payload.tabValue === 0) {
				const postSwrIndex: number = state.single_user_profile.posts.findIndex(
					(post: PostInterface) => post._id === action.payload.payload[0]._id,
				);
				state.single_user_profile.posts[postSwrIndex].comments = action.payload.payload[0].comments;
			} else {
				const postSwrIndex: number = state.single_user_profile.saved_items.posts.findIndex(
					(post: PostInterface) => post._id === action.payload.payload[0]._id,
				);
				state.single_user_profile.saved_items.posts[postSwrIndex].comments = action.payload.payload[0].comments;
			}
		}
	},
	prepare: (payload: {
		tabValue: number;
		response: {
			status: string;
			payload: any;
			missingFields: Array<string> | null;
			error: string | null;
		};
	}) => ({
		payload: { payload: payload.response.payload, tabValue: payload.tabValue },
	}),
};

export const deleteServiceDetails = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.services = action.payload.payload.services;
		state.single_business_profile.servicesCount = action.payload.payload.serviceCount;
		state.business_profile_snackbar.delete_service_listing = true;
	},
	prepare: (payload: any) => ({
		payload: payload,
	}),
};

export const setProfileContext = {
	reducer: (state: StateInterface, action: AnyAction) => {
		if (action.payload) {
			state.profile_context = {
				is_business_profile: action.payload.profile_context.is_business_profile,
				user_id: action.payload._id,
				business_profile_id: action.payload.profile_context.business_profile_id,
				business_profile_image: action.payload.profile_context.business_profile_image,
				page_type: action.payload.profile_context.page_type,
				page_title: action.payload.profile_context.page_title,
				page_slug: action.payload.profile_context.page_slug,
				subscription_information: action.payload.profile_context.subscription_information,
				kyc_info: action.payload.profile_context.kyc_info,
				remember_choice: action.payload.profile_context.remember_choice,
			};

			if (
				state.redux_session !== null &&
				action.payload.profile_context.is_business_profile === true &&
				state.redux_session.user.dbUser.pages.length < 2 &&
				state.redux_session.user.dbUser.pages.filter(
					(item: any) => item.page_id === action.payload.profile_context.business_profile_id,
				).length === 0
			) {
				state.redux_session.user.dbUser.pages = [
					{
						_id: action.payload.profile_context.business_profile_id,
						page_id: action.payload.profile_context.business_profile_id,
						page_type: action.payload.profile_context.page_type,
						page_title: action.payload.profile_context.page_title,
						page_slug: action.payload.profile_context.page_slug,
						display_picture_url: action.payload.profile_context.business_profile_image,
						subscription_information: action.payload.profile_context.subscription_information,
						kyc_info: action.payload.profile_context.kyc_info,
					},
					...state.redux_session.user.dbUser.pages,
				];
			}
		}
	},
	prepare: (payload: {
		status: string;
		payload: UserInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const setActionForbooms = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.actionForBooms = action.payload;
	},
	prepare: (payload: StateInterface["actionForBooms"]) => ({
		payload: payload,
	}),
};
export const profileContext = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.profile_context = action.payload;
	},
	prepare: (payload: StateInterface["profile_context"]) => ({
		payload: {
			...payload,
			user_id: payload.user_id.toString(),
			business_profile_id: payload.business_profile_id.toString(),
		},
	}),
};
export const bookmark = {
	reducer: (state: StateInterface, action: AnyAction) => {
		// if (action.payload.is_business_profile) {
		//   const postIndex: number = state.single_business_profile.posts.findIndex(
		//     (post: PostInterface) => post._id === action.payload[0]._id
		//   );
		//   state.single_business_profile.posts[postIndex].saved_content =
		//     action.payload[0].saved_content;
		//   const postSwrIndex: number = state.postSwrForBusiness.findIndex(
		//     (post: PostInterface) => post._id === action.payload[0]._id
		//   );
		//   state.postSwrForBusiness[postSwrIndex].blasts = action.payload[0].blasts;
		// } else {
		//   const postIndex: number = state.single_user_profile.posts.findIndex(
		//     (post: PostInterface) => post._id === action.payload[0]._id
		//   );
		//   state.single_user_profile.posts[postIndex].saved_content =
		//     action.payload[0].saved_content;
		//   const postSwrIndex: number = state.postSwrForUser.findIndex(
		//     (post: PostInterface) => post._id === action.payload[0]._id
		//   );
		//   state.postSwrForUser[postSwrIndex].blasts = action.payload[0].blasts;
		// }
	},
	prepare: (payload: {
		status: string;
		payload: BusinessProfileInterface | UserInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const reportPost = {
	reducer: (state: StateInterface, action: AnyAction) => {
		if (action.payload.is_Business_Profile) {
			const postIndex: number = state.single_business_profile.posts.findIndex(
				(post: PostInterface) => post._id === action.payload[0]._id,
			);
			state.single_business_profile.posts[postIndex].flags = action.payload[0].flags;
		} else {
			const postIndex: number = state.single_user_profile.posts.findIndex(
				(post: PostInterface) => post._id === action.payload[0]._id,
			);
			state.single_user_profile.posts[postIndex].flags = action.payload[0].flags;
		}
	},
	prepare: (payload: {
		status: string;
		payload: BusinessProfileInterface | UserInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const postSwrForBusiness = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.postSwrForBusiness = action.payload;
	},
	prepare: (payload: StateInterface["postSwrForBusiness"]) => ({
		payload: payload,
	}),
};

export const postSwrForUser = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.postSwrForUser = action.payload;
	},
	prepare: (payload: StateInterface["postSwrForUser"]) => ({
		payload: payload,
	}),
};

export const commentSwr = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.comment_swr = action.payload;
	},
	prepare: (payload: StateInterface["comment_swr"]) => ({
		payload: payload,
	}),
};

export const publishproperty = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.status = action.payload.status;
		state.single_property_page.property.published = action.payload.published;
	},
	prepare: (payload: StateInterface["property"] | any) => ({
		payload: payload.payload,
	}),
};

export const publicproperty = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property = action.payload.payload;
	},
	prepare: (payload: StateInterface["property"]) => ({
		payload: payload,
	}),
};

export const publishservice = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data.status = action.payload.payload.status;
		state.single_service_page.service_data.published = action.payload.payload.published;
	},
	prepare: (payload: StateInterface["service"]) => ({
		payload: payload,
	}),
};

export const publicservice = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_service_page.service_data = action.payload.payload;
	},
	prepare: (payload: StateInterface["service"]) => ({
		payload: payload,
	}),
};

export const OnBoarding = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.user = action.payload.payload;
	},
	prepare: (payload: StateInterface["user"]) => ({
		payload: payload,
	}),
};
export const updateNofEmployees = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.no_of_employees = action.payload.no_of_employees;
		state.business_profile_snackbar.no_of_employees = true;
	},
	prepare: (payload: {
		status: string;
		payload: BusinessProfileInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};
export const storeleads = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.cta_leads = action.payload;
	},
	prepare: (payload: {
		status: string;
		payload: CtaLeadsInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};
export const setSavedIsPublic = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile = action.payload;
	},
	prepare: (payload: {
		status: string;
		payload: BusinessProfileInterface;
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload.payload,
	}),
};

export const fetchSinglePost = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_post_page = action.payload;
	},
	prepare: (payload: StateInterface["single_post_page"]) => ({
		payload: payload,
	}),
};

export const businessProfileListTabView = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.business_profile_listings_tab_view = action.payload;
	},
	prepare: (payload: StateInterface["business_profile_listings_tab_view"]) => ({
		payload: payload,
	}),
};

export const setSearchProperties = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.properties = action.payload;
	},
	prepare: (payload: StateInterface["single_business_profile"]["properties_og"]) => ({
		payload: payload,
	}),
};
export const setSearchServices = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.services = action.payload;
	},
	prepare: (payload: StateInterface["single_business_profile"]["services_og"]) => ({
		payload: payload,
	}),
};
export const setSearchOFBProperties = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.ofb_properties = action.payload;
	},
	prepare: (payload: StateInterface["single_business_profile"]["properties_og"]) => ({
		payload: payload,
	}),
};

export const loadMoreProperties = {
	reducer: (state: StateInterface, action: AnyAction) => {
		//@ts-ignore
		state.single_business_profile.properties.push(...action.payload.payload);
	},
	prepare: (payload: StateInterface["single_business_profile"]["properties"]) => ({
		payload: payload,
	}),
};

export const loadMoreServices = {
	reducer: (state: StateInterface, action: AnyAction) => {
		//@ts-ignore
		state.single_business_profile.services.push(...action.payload.payload);
	},
	prepare: (payload: StateInterface["service_list"]) => ({
		payload: payload,
	}),
};

export const loadMoreOfbProperty = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.ofb_properties.push(
			//@ts-ignore
			...action.payload.payload,
		);
	},
	prepare: (payload: StateInterface["single_business_profile"]["ofb_properties"]) => ({
		payload: payload,
	}),
};

export const loadMoreSavedProperties = {
	reducer: (state: StateInterface, action: AnyAction) => {
		//@ts-ignore
		state.single_business_profile.saved_items.properties.push(...action.payload.payload);
	},
	prepare: (payload: StateInterface["property"]) => ({
		payload: payload,
	}),
};

export const loadMoreSavedOFB = {
	reducer: (state: StateInterface, action: AnyAction) => {
		//@ts-ignore
		state.single_business_profile.saved_items.ofb.push(...action.payload.payload);
	},
	prepare: (payload: StateInterface["property"]) => ({
		payload: payload,
	}),
};

export const loadMoreUserSavedProperties = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.saved_properties_list = action.payload.payload;
	},
	prepare: (payload: StateInterface["saved_properties_list"]) => ({
		payload: payload,
	}),
};

export const loadMoreSavedServices = {
	reducer: (state: StateInterface, action: AnyAction) => {
		//@ts-ignore
		state.single_business_profile.saved_items.services.push(...action.payload.payload);
	},
	prepare: (payload: StateInterface["service"]) => ({
		payload: payload,
	}),
};

export const loadMoreUserSavedServices = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.saved_services_list = action.payload.payload;
	},
	prepare: (payload: StateInterface["saved_services_list"]) => ({
		payload: payload,
	}),
};

export const loadMoreSavedPosts = {
	reducer: (state: StateInterface, action: AnyAction) => {
		//@ts-ignore
		state.single_business_profile.saved_items.posts.push(...action.payload.payload.posts);
	},
	prepare: (payload: any) => ({
		payload: payload,
	}),
};

export const loadMoreUserSavedPosts = {
	reducer: (state: StateInterface, action: AnyAction) => {
		//@ts-ignore
		state.single_user_profile.saved_items.posts.push(...action.payload.payload.posts);
	},
	prepare: (payload: any) => ({
		payload: payload,
	}),
};

export const loadMoreUserSavedOFB = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.saved_items.ofb.push(...action.payload.payload);
	},
	prepare: (payload: StateInterface["property"]) => ({
		payload: payload,
	}),
};

export const loadMoreUserProperties = {
	reducer: (state: StateInterface, action: AnyAction) => {
		//@ts-ignore
		state.single_user_profile.ofb_properties.push(...action.payload.payload);
	},
	prepare: (payload: StateInterface["single_user_profile"]["ofb_properties"]) => ({
		payload: payload,
	}),
};

export const searchPropertyListings = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.searched_property = action.payload.payload;
	},
	prepare: (payload: StateInterface["single_business_profile"]["searched_property"]) => ({
		payload: payload,
	}),
};

export const searchUserPropertyListings = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.searched_properties = action.payload.payload;
	},
	prepare: (payload: StateInterface["single_user_profile"]["searched_properties"]) => ({
		payload: payload,
	}),
};

export const changeRecievePromotionalEmails = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.account_settings_page.user_data.user_privacy.send_promotional_emails =
			action.payload.receive_promotional_emails;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const changeAutoplayVideos = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.account_settings_page.user_data.settings.site_preferences.autoplay_videos = action.payload.autoplay_videos;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const changeDefaultVideoQuality = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.account_settings_page.user_data.settings.site_preferences.default_video_quality = action.payload.f;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const CreateNewBugReport = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.bug_report = action.payload;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const CreateNewReport = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.report = action.payload;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchSingleUserProfileCoverImageAndProfilePicture = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.user_profile._id = action.payload._id;
		state.single_user_profile.user_profile.cover_image = action.payload.cover_image;
		state.single_user_profile.user_profile.profile_picture = action.payload.profile_picture;
		state.single_user_profile.user_profile.first_name = action.payload.first_name;
		state.single_user_profile.user_profile.last_name = action.payload.last_name;
		state.single_user_profile.user_profile.verified = action.payload.verified;
		state.user_profile_loading.userProfileImages = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchSingleUserProfileFollowersAndFollowings = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.user_profile.followers = action.payload.followers;
		state.single_user_profile.user_profile.following = action.payload.following;
		state.user_profile_loading.userProfileFollowers = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchSingleUserProfileAboutDetails = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.user_profile.about = action.payload.about;
		state.single_user_profile.user_profile.phone = action.payload.phone;
		state.single_user_profile.user_profile.recovery_email = action.payload.recovery_email;
		state.single_user_profile.user_profile.country_code = action.payload.country_code;
		state.single_user_profile.user_profile.date_of_birth = action.payload.date_of_birth;
		state.single_user_profile.user_profile.assigned_to = action.payload.assigned_to;
		state.single_user_profile.user_profile.verified = action.payload.verified;
		state.single_user_profile.user_profile.is_subscribed = action.payload.subscription_active;
		// if (action.payload) {
		state.user_profile_loading.userProfileAboutDetails = false;
		// }
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const fetchSingleUserProfilePosts = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.posts = action.payload.posts;
		state.single_user_profile.postsCount = action.payload.postsCount;
		state.user_profile_loading.userProfileProfilePostCard = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchSingleUserProfileListings = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.ofb_properties = action.payload.ofb_properties;
		state.single_user_profile.propertiesCount = action.payload.propertiesCount;
		state.user_profile_loading.userProfileOfbPropertyListings = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchSingleUserProfileReviews = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.reviews = action.payload.reviews;
		state.single_user_profile.reviewsCount = action.payload.reviewsCount;
		state.user_profile_loading.userProfileReviews = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const fetchSingleUserProfileGivenReviews = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.given_reviews = action.payload.reviews;
		state.single_user_profile.given_reviews_count = action.payload.givenReviewsCount;
		state.user_profile_loading.userProfileReviews = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchSingleUserSavedProperties = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.saved_items.properties = action.payload.properties;
		state.single_user_profile.savedPropertiesLength = action.payload.propertiesCount;
		state.user_profile_loading.userProfileSavedProperty = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchSingleUserSavedPost = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.saved_items.posts = action.payload.posts;
		state.single_user_profile.savedPostsLength = action.payload.savedPostLength;
		state.user_profile_loading.userProfileSavedPost = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchSingleUserSavedService = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.saved_items.services = action.payload.services;
		state.single_user_profile.savedServicesLength = action.payload.savedServicesLength;
		state.user_profile_loading.userProfileSavedService = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchSingleUserSavedOFB = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.saved_items.ofb = action.payload.properties;
		state.single_user_profile.savedOFBLength = action.payload.propertiesCount;
		state.user_profile_loading.userProfileSavedOfb = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const fetchSingleUserAnalytics = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.analytics = action.payload.analytics;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const setHomeLocation = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.home_location = action.payload;
	},
	prepare: (payload: StateInterface["home_location"]) => ({
		payload: payload,
	}),
};
export const setHomeLoading = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.home_loading = action.payload;
	},
	prepare: (payload: StateInterface["home_loading"]) => ({
		payload: payload,
	}),
};
export const setUserLoading = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.user_profile_loading = action.payload;
	},
	prepare: (payload: StateInterface["user_profile_loading"]) => ({
		payload: payload,
	}),
};

export const fetchHomePageHotPropertiesData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.homePage.hot_properties = action.payload.hot_properties;
		state.home_loading.loading_hot_properties = false;
	},
	prepare: (payload: StateInterface["homePage"]) => ({
		payload: payload,
	}),
};
export const fetchHomePageFeaturedPropertiesData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.homePage.featured_properties = action.payload.featured_properties;
		state.home_loading.loading_featured_properties = false;
	},
	prepare: (payload: StateInterface["homePage"]) => ({
		payload: payload,
	}),
};
export const fetchHomePageTopServicesData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.homePage.top_services = action.payload.top_services;
		state.home_loading.loading_top_services = false;
	},
	prepare: (payload: StateInterface["homePage"]) => ({
		payload: payload,
	}),
};
// export const fetchHomePageTrendingServicesData = {
// 	reducer: (state: StateInterface, action: AnyAction) => {
// 		state.homePage.trending_services = action.payload.trending_services;
// 		state.home_loading.loading_trending_services = false;
// 	},
// 	prepare: (payload: StateInterface["homePage"]) => ({
// 		payload: payload,
// 	}),
// };
export const fetchHomePageTopUsersData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.homePage.top_users = action.payload.top_users;
		state.home_loading.loading_top_users = false;
	},
	prepare: (payload: StateInterface["homePage"]) => ({
		payload: payload,
	}),
};
// export const fetchHomePageTrendingUsersData = {
// 	reducer: (state: StateInterface, action: AnyAction) => {
// 		state.homePage.trending_users = action.payload.trending_users;
// 		state.home_loading.loading_trending_users = false;
// 	},
// 	prepare: (payload: StateInterface["homePage"]) => ({
// 		payload: payload,
// 	}),
// };
export const fetchHomePageTopBusinessProfilesData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.homePage.top_business_profiles = action.payload.top_business_profiles;
		state.home_loading.loading_top_business_profiles = false;
	},
	prepare: (payload: StateInterface["homePage"]) => ({
		payload: payload,
	}),
};
// export const fetchHomePageTrendingBusinessProfilesData = {
// 	reducer: (state: StateInterface, action: AnyAction) => {
// 		state.homePage.trending_business_profiles = action.payload.trending_business_profiles;
// 		state.home_loading.loading_trending_business_profiles = false;
// 	},
// 	prepare: (payload: StateInterface["homePage"]) => ({
// 		payload: payload,
// 	}),
// };

export const setHomePageBusinessProfileLoading = {
	reducer: (state: StateInterface, action: AnyAction) => {
		if (action.payload) {
			state.homePage.trending_business_profiles = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
			state.homePage.top_business_profiles = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
		}
	},
	prepare: (payload: boolean) => ({
		payload: payload,
	}),
};
export const setHomePagePropertiesLoading = {
	reducer: (state: StateInterface, action: AnyAction) => {
		if (action.payload) {
			state.homePage.featured_properties = [0, 0, 0, 0, 0, 0];
			state.homePage.hot_properties = [0, 0, 0, 0, 0, 0];
		}
	},
	prepare: (payload: boolean) => ({
		payload: payload,
	}),
};

export const fetchBusinessProfileImages = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile._id = action.payload.business_profile._id;
		state.single_business_profile.business_profile.cover_image = action.payload.business_profile.cover_image;
		state.single_business_profile.business_profile.display_picture_url =
			action.payload.business_profile.display_picture_url;
		state.single_business_profile.business_profile.verified = action.payload.business_profile.verified;
		state.single_business_profile.business_profile.title = action.payload.business_profile.title;
		state.single_business_profile.business_profile.business_profile_type =
			action.payload.business_profile.business_profile_type;
		state.single_business_profile.business_profile.is_subscribed = action.payload.business_profile.is_subscribed;
		state.business_profile_loading.businessProfileImages = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const fetchBusinessProfileFollowerAndFollowing = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.followers = action.payload.followers;
		state.single_business_profile.business_profile.following = action.payload.following;
		state.business_profile_loading.businessProfileFollowers = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const fetchBusinessProfileAboutDetails = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.about = action.payload.about;
		state.single_business_profile.business_profile.phone = action.payload.phone;
		state.single_business_profile.business_profile.email = action.payload.email;
		state.single_business_profile.business_profile.admins = action.payload.admins;
		state.single_business_profile.business_profile.industry_type = action.payload.industry_type;
		state.single_business_profile.business_profile.created_by = action.payload.created_by;
		state.single_business_profile.business_profile.created_at = action.payload.created_at;
		state.single_business_profile.business_profile.slug = action.payload.slug;
		state.single_business_profile.business_profile.assigned_to = action.payload.assigned_to;
		state.business_profile_loading.businessProfileAboutDetails = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const fetchBusinessProfileServiceOffered = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.services_offered = action.payload.services_offered;
		state.business_profile_loading.businessProfileServicesOffered = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchBusinessProfileSubscription = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.subscription_information = action.payload.subscription_information;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchBusinessProfileExpertise = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.expertise = action.payload.expertise;
		state.business_profile_loading.businessProfileExpertise = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchBusinessProfileBrokerage = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.agent_brokerage = action.payload.agent_brokerage;
		state.business_profile_loading.businessProfileBrokerage = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchBusinessProfileWhychoose = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.why_choose_us = action.payload.why_choose_us;
		state.business_profile_loading.businessProfileWhyChooseUs = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchBusinessProfileHiring = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.hiring = action.payload.hiring;
		state.business_profile_loading.businessProfileJobOpenings = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchBusinessProfileHonorsAndAwards = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.awards_and_honors = action.payload.awards_and_honors;
		state.business_profile_loading.businessProfileHonorsAndAwards = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchBusinessProfileFaqs = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.faqs = action.payload.faqs;
		state.business_profile_loading.businessProfileFaqs = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchBusinessProfileLanguages = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.languages = action.payload.languages;
		state.business_profile_loading.businessProfileLanguage = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchBusinessProfileLicencesAndCertificates = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.licences_and_certifications =
			action.payload.licences_and_certifications;
		state.business_profile_loading.businessProfileLicences = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchBusinessProfileNoOfEmployees = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.no_of_employees = action.payload.no_of_employees;
		state.business_profile_loading.businessProfileEmployees = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchBusinessProfileListingProperty = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.properties = action.payload.property;
		state.single_business_profile.propCount = action.payload.propertyCount;
		state.business_profile_loading.businessProfileListingProperty = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchBusinessProfileReviews = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.reviews = action.payload.reviews;
		state.single_business_profile.reviewsCount = action.payload.reviewsCount;
		state.business_profile_loading.businessProfileReviews = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchBusinessProfileListingService = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.services = action.payload.services;
		state.single_business_profile.servicesCount = action.payload.servicesCount;
		state.business_profile_loading.businessProfileListingService = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchBusinessProfileListingOFB = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.ofb_properties = action.payload.OfbProperty;
		state.single_business_profile.ofbpropertiesCount = action.payload.OfbPropertyCount;
		state.business_profile_loading.businessProfileListingOFB = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchBusinessProfileLeads = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.leads = action.payload.leads;
		state.business_profile_loading.buinessProfileLeads = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchBusinessProfileSavedProperty = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.saved_items.properties = action.payload.properties;
		state.single_business_profile.savedPropertiesLength = action.payload.propertiesCount;
		state.business_profile_loading.businessProfileSavedProperty = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchBusinessProfileSavedService = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.saved_items.services = action.payload.services;
		state.single_business_profile.savedServicesLength = action.payload.servicesLength;
		state.business_profile_loading.businessProfileSavedService = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const fetchBusinessProfileSavedOFB = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.saved_items.ofb = action.payload.ofb;
		state.single_business_profile.savedOFBLength = action.payload.savedOFBLength;
		state.business_profile_loading.businessProfileSavedOFB = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchBusinessProfileSavedPost = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.saved_items.posts = action.payload.posts;
		state.single_business_profile.savedPostsLength = action.payload.savedPostLength;
		state.business_profile_loading.businessProfileSavedPost = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const fetchBusinessProfileAnalyticsProperty = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.analytics.property_views_per_day = action.payload.property_views_per_day;
		state.business_profile_loading.businessProfileAnalyticsProperty = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const fetchBusinessProfileAnalyticsService = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.analytics.service_views_per_day = action.payload.service_views_per_day;
		state.business_profile_loading.businessProfileAnalyticsService = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchBusinessProfileAnalyticsPost = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.analytics.post_blasts_per_day = action.payload.post_blasts_per_day;
		state.single_business_profile.analytics.post_views_per_day = action.payload.post_views_per_day;
		state.single_business_profile.analytics.post_booms_per_day = action.payload.post_booms_per_day;
		state.business_profile_loading.businessProfileAnalyticsPost = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const fetchBusinessProfileAnalyticsOFBs = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.analytics.ofb_property_views_per_day = action.payload.ofb_property_views_per_day;
		state.business_profile_loading.businessProfileAnalyticsOFB = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const fetchBusinessProfileAdminsDetailsAndEmployeeRequest = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.admins = action.payload.business_profile.admins;
		state.single_business_profile.business_profile.admin_user_details =
			action.payload.business_profile.admin_user_details;
		state.single_business_profile.business_profile.employee_details = action.payload.business_profile.employee_details;
		state.business_profile_loading.businessProfileAdmin = false;
		state.business_profile_loading.businessProfileEmployeeRequest = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const fetchPropertyDataReviewsPageData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.property_reviews_page.property_data = action.payload.property_data;
	},
	prepare: (payload: StateInterface["property_reviews_page"]) => ({
		payload: payload,
	}),
};
export const fetchPropertyUserDataReviewsPageData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.property_reviews_page.property_reviews = action.payload.property_reviews;
	},
	prepare: (payload: StateInterface["property_reviews_page"]) => ({
		payload: payload,
	}),
};

export const fetchServiceDataReviewsPageData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.service_reviews_page.service_data = action.payload.service_data;
	},
	prepare: (payload: StateInterface["service_reviews_page"]) => ({
		payload: payload,
	}),
};
export const fetchServiceUserDataReviewsPageData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.service_reviews_page.service_reviews = action.payload.service_data;
	},
	prepare: (payload: StateInterface["service_reviews_page"]) => ({
		payload: payload,
	}),
};

export const fetchSinglePropertyDetails = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.price = action.payload.property_data.price;
		state.single_property_page.property.price_per_unit = action.payload.property_data.price_per_unit;
		state.single_property_page.property.transaction_type = action.payload.property_data.transaction_type;
		state.single_property_page.property.property_category = action.payload.property_data.property_category;
		state.single_property_page.property.property_type = action.payload.property_data.property_type;
		state.single_property_page.property.property_subtype = action.payload.property_data.property_subtype;
		state.single_property_page.property.tags = action.payload.property_data.tags;
		state.single_property_page.property.is_ofb = action.payload.property_data.is_ofb;
		state.single_property_page.property.unit_facing = action.payload.property_data.unit_facing;
		state.single_property_page.property.land_facing = action.payload.property_data.land_facing;
		state.single_property_page.property.property_age = action.payload.property_data.property_age;
		state.single_property_page.property.security_deposit = action.payload.property_data.security_deposit;
		state.single_property_page.property.availability = action.payload.property_data.availability;
		state.single_property_page.property.possession_date = action.payload.property_data.possession_date;
		state.property_loading.propertyDetails = false;
	},
	prepare: (payload: StateInterface["single_property_page"]) => ({
		payload: payload,
	}),
};

export const fetchNearbyProperties = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.nearbyProperties = action.payload.nearbyProperties;
	},
	prepare: (payload: StateInterface["single_property_page"]) => ({
		payload: payload,
	}),
};

export const setSinglePropertyTransactionType = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.transaction_type = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertyTransactionType = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.transaction_type = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertyCurrentPage = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.currentPage = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

export const setCreateNewServiceCurrentPage = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_service_data.currentPage = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

export const setSinglePropertyCategoryType = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.property_category = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertyCategoryType = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.property_category = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

export const setSinglePropertyType = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.property_type = action.payload;
	},
	prepare: (payload: Array<string>) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertyType = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.property_type = action.payload;
	},
	prepare: (payload: Array<string>) => ({
		payload: payload,
	}),
};

export const setSinglePropertySubType = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.property_subtype = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertySubType = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.property_subtype = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

export const setSinglePropertyMinPrice = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.price.min = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertyMinPrice = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.price.min = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setSinglePropertyMaxPrice = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.price.max = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertyMaxPrice = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.price.max = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setSinglePropertySecurityDeposit = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.security_deposit.max = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertySecurityDeposit = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.security_deposit.max = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setSinglePropertyAvailabilty = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.availability = action.payload;
	},
	prepare: (payload: Array<string>) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertyAvailabilty = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.availability = action.payload;
	},
	prepare: (payload: Array<string>) => ({
		payload: payload,
	}),
};

export const setSinglePropertyPossessionDate = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.possession_date = action.payload;
	},
	prepare: (payload: string | Date) => ({
		payload: payload,
	}),
};

export const setCreateNewePropertyPossessionDate = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.possession_date = action.payload;
	},
	prepare: (payload: string | Date) => ({
		payload: payload,
	}),
};

export const setSinglePropertyTags = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.tags = action.payload;
	},
	prepare: (payload: Array<string>) => ({
		payload: payload,
	}),
};

export const setSinglePropertyUnitFacing = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.unit_facing = action.payload;
	},
	prepare: (payload: Array<string>) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertyUnitFacing = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.unit_facing = action.payload;
	},
	prepare: (payload: Array<string>) => ({
		payload: payload,
	}),
};

export const setSinglePropertyLandFacing = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.land_facing = action.payload;
	},
	prepare: (payload: Array<string>) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertyLandFacing = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.land_facing = action.payload;
	},
	prepare: (payload: Array<string>) => ({
		payload: payload,
	}),
};

export const setSinglePropertyAge = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.property_age = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertyAge = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.property_age = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setSinglePropertyFurnishing = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.furnished = action.payload;
	},
	prepare: (payload: Array<string>) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertyFurnishing = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.furnished = action.payload;
	},
	prepare: (payload: Array<string>) => ({
		payload: payload,
	}),
};

export const setSinglePropertyPlotDimensions = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.plot_dimensions = action.payload;
	},
	prepare: (payload: Array<string>) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertyPlotDimensions = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.plot_dimensions = action.payload;
	},
	prepare: (payload: Array<string>) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertyOverview = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.description = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

export const setSinglePropertyOtherPlotDimensions = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.other_plot_dimensions = action.payload;
	},
	prepare: (payload: Array<string>) => ({
		payload: payload,
	}),
};

export const setSinglePropertyLandArea = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.area.land_area.max.acre = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertyLandArea = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.area.land_area.max.acre = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertyLandAreaUnit = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.area.land_area.land_area_unit = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertySuperBuiltUpAreaUnit = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.area.super_built_up_area.super_built_up_area_unit = action.payload;
		state.new_property_data.area.carpet_area.carpet_area_unit = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

export const setSinglePropertyMinSuperBuiltUpArea = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.area.super_built_up_area.min.ft = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setCreateNePropertyMinSuperBuiltUpArea = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.area.super_built_up_area.min.ft = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setSinglePropertyMaxSuperBuiltUpArea = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.area.super_built_up_area.max.ft = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertyMaxSuperBuiltUpArea = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.area.super_built_up_area.max.ft = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setSinglePropertyMinCarpetArea = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.area.carpet_area.min.ft = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertyMinCarpetArea = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.area.carpet_area.min.ft = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setSinglePropertyMaxCarpetArea = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.area.carpet_area.max.ft = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertyMaxCarpetArea = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.area.carpet_area.max.ft = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setSinglePropertyMinBedrooms = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.bhk.min = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertyMinBedrooms = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.bhk.min = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setSinglePropertyMaxBedrooms = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.bhk.max = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertyMaxBedrooms = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.bhk.max = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setSinglePropertyMinBathrooms = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.bathroom.min = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertyMinBathrooms = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.bathroom.min = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setSinglePropertyMaxBathrooms = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.bathroom.max = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertyMaxBathrooms = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.bathroom.max = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setSinglePropertyMinBalconies = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.balconies.min = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setCreateNePropertyMinBalconies = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.balconies.min = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setSinglePropertyMaxBalconies = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.balconies.max = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertyMaxBalconies = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.balconies.max = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setSinglePropertyMaxFloors = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.floors.max = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertyMaxFloors = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.floors.max = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setSinglePropertyFloorNo = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.floor_no.max = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};
export const setCreateNewPropertyFloorNo = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.floor_no.max = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setSinglePropertyNoOfUnits = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.no_of_units.max = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertyNoOfUnits = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.no_of_units.max = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setSinglePropertyNoOfSeats = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.no_of_seats.max = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertyNoOfSeats = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.no_of_seats.max = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setCreatePropertySelectedImagesUrl = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.images = action.payload;
	},
	prepare: (payload: Array<string>) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertyUploadImages = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.images = action.payload;
	},
	prepare: (
		payload: Array<{
			file_id: ObjectId | string;
			mime_type: string;
			category: string;
			caption: string;
			priority: number;
			stage: string;
		}>,
	) => ({
		payload: payload,
	}),
};

export const setCreateNewPropertyDefaultLocation = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_property_data.search_metrics.location_coordinates = action.payload.location_coordinates;
		state.new_property_data.location.title = action.payload.address;
	},
	prepare: (payload: { location_coordinates: Array<number>; address: string }) => ({
		payload: payload,
	}),
};

export const setCreateNewServiceCategory = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_service_data.service_category = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

export const setCreateNewServiceType = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_service_data.service_type = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

export const setCreateNewServiceSubType = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_service_data.service_subtype = action.payload;
	},
	prepare: (payload: Array<string>) => ({
		payload: payload,
	}),
};

export const setCreateNewServiceOtherSubType = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_service_data.other_service_subtype = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

export const setCreateNewServiceShowPrice = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_service_data.price.show_price = action.payload;
	},
	prepare: (payload: boolean) => ({
		payload: payload,
	}),
};

export const setCreateNewServiceMinPrice = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_service_data.price.min = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setCreateNewServiceMaxPrice = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_service_data.price.max = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setCreateNewServiceUploadImages = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_service_data.images = action.payload;
	},
	prepare: (
		payload: Array<{
			file_id: ObjectId;
			mime_type: string;
			category: string;
			caption: string;
			priority: number;
		}>,
	) => ({
		payload: payload,
	}),
};

export const setCreateNewServiceDefaultLocation = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_service_data.search_metrics.location_coordinates = action.payload.location_coordinates;
		state.new_service_data.location.title = action.payload.address;
	},
	prepare: (payload: { location_coordinates: Array<number>; address: string }) => ({
		payload: payload,
	}),
};

export const setCreateNewServiceProviderName = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.new_service_data.service_provider.name = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

export const setSinglePropertyAmenities = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.amenities = action.payload;
	},
	prepare: (
		payload: Array<{
			id: string;
			label: string;
			icon: string;
		}>,
	) => ({
		payload: payload,
	}),
};

export const setSinglePropertyOverview = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.description = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

export const setSinglePropertyAbout = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.about_property = action.payload;
	},
	prepare: (payload: Array<string>) => ({
		payload: payload,
	}),
};

export const setSinglePropertyDeveloperName = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.builder.name = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

export const setSinglePropertyEstablishedYear = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.builder.year_est = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};

export const setSinglePropertyDescription = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.builder.description = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

export const setSinglePropertyAboutLocation = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.location.about_location = action.payload;
	},
	prepare: (payload: Array<string>) => ({
		payload: payload,
	}),
};

export const setSinglePropertyVicinity = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.vicinity = action.payload;
	},
	prepare: (
		payload: Array<{
			category: string;
			values: Array<{
				title: string;
				distance: number;
			}>;
		}>,
	) => ({
		payload: payload,
	}),
};

export const fetchAboutSingleProperty = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.about_property = action.payload.property_data.about_property;
		state.property_loading.aboutSingleProperty = false;
	},
	prepare: (payload: StateInterface["single_property_page"]) => ({
		payload: payload,
	}),
};

export const fetchSinglePropertyCreator = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.created_by = action.payload.property_data.created_by;
		state.property_loading.singlePropertyCreator = false;
	},
	prepare: (payload: StateInterface["single_property_page"]) => ({
		payload: payload,
	}),
};

export const fetchSinglePropertyAboutLocation = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.location = action.payload.property_data.location;
		state.property_loading.singlePropertyAboutLocation = false;
	},
	prepare: (payload: StateInterface["single_property_page"]) => ({
		payload: payload,
	}),
};

export const fetchSinglePropertyAmenities = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.amenities = action.payload.property_data.amenities;
		state.property_loading.singlePropertyAmenities = false;
	},
	prepare: (payload: StateInterface["single_property_page"]) => ({
		payload: payload,
	}),
};

export const fetchSinglePropertyDeveloper = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.builder = action.payload.property_data.builder;
		state.property_loading.singlePropertyDeveloper = false;
	},
	prepare: (payload: StateInterface["single_property_page"]) => ({
		payload: payload,
	}),
};
export const fetchSinglePropertyImages = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property._id = action.payload.property_data._id;
		state.single_property_page.property.slug = action.payload.property_data.slug;
		state.single_property_page.property.images = action.payload.property_data.images;
		state.single_property_page.property.title = action.payload.property_data.title;
		state.property_loading.singlePropertyImages = false;
		state.single_property_page.property.saved_content = action.payload.property_data.saved_content;
		state.single_property_page.property.location = action.payload.property_data.location;
		state.single_property_page.property.show_contact_details = action.payload.property_data.show_contact_details;
		state.single_property_page.property.allow_copy = action.payload.property_data.allow_copy;
		state.single_property_page.property.linked = action.payload.property_data.linked;
		state.single_property_page.property.linked_to = action.payload.property_data.linked_to;
		state.single_property_page.property.published = action.payload.property_data.published;
		state.single_property_page.property.status = action.payload.property_data.status;
		state.single_property_page.property.assigned_to = action.payload.property_data.assigned_to;
	},
	prepare: (payload: StateInterface["single_property_page"]) => ({
		payload: payload,
	}),
};
export const fetchSinglePropertyLocationCoordinates = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.search_metrics.location_coordinates =
			action.payload.property_data.search_metrics.location_coordinates;
		state.location = {
			location: [
				action.payload.property_data.search_metrics.location_coordinates[1],
				action.payload.property_data.search_metrics.location_coordinates[0],
			],
		};
		state.property_loading.singlePropertyLocationCoordinates = false;
	},
	prepare: (payload: StateInterface["single_property_page"]) => ({
		payload: payload,
	}),
};

export const fetchSinglePropertyOverview = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.description = action.payload.property_data.description;
		state.single_property_page.property.is_overview_ai_generated =
			action.payload.property_data.is_overview_ai_generated;
		state.property_loading.singlePropertyOverview = false;
		state.single_property_page.property.manual_overview = action.payload.property_data.manual_overview;
		state.single_property_page.property.use_data_for_fine_tuning =
			action.payload.property_data.use_data_for_fine_tuning;
	},
	prepare: (payload: StateInterface["single_property_page"]) => ({
		payload: payload,
	}),
};

export const fetchSinglePropertyReviews = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.propertyReviews = action.payload.property_data;
		state.property_loading.singlePropertyReviews = false;
	},
	prepare: (payload: StateInterface["single_property_page"]) => ({
		payload: payload,
	}),
};

export const fetchSinglePropertyVicinity = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.vicinity = action.payload.property_data.vicinity;
		state.property_loading.singlePropertyVicinity = false;
	},
	prepare: (payload: StateInterface["single_property_page"]) => ({
		payload: payload,
	}),
};

export const fetchSinglePropertyTrendingProperties = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.trendingProperties = action.payload.trending_properties;
		state.property_loading.singlePropertyTrendingProperties = false;
	},
	prepare: (payload: StateInterface["single_property_page"]) => ({
		payload: payload,
	}),
};

export const fetchSinglePropertyHotProperties = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.hotProperties = action.payload.hot_properties;
		state.property_loading.singlePropertyHotProperties = false;
	},
	prepare: (payload: StateInterface["single_property_page"]) => ({
		payload: payload,
	}),
};

export const fetchSinglePropertyNeighbourhoodReviews = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.neighbourhoodReviews = action.payload.property_data;
		state.property_loading.singlePropertyNeighbourhoodReviews = false;
	},
	prepare: (payload: StateInterface["single_property_page"]) => ({
		payload: payload,
	}),
};

export const fetchAccountSettingsUserData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.account_settings_page.user_data = action.payload.user_data;
		state.account_setting_loading.loading_personal_info = false;
		state.account_setting_loading.loading_kyc = false;
	},
	prepare: (payload: {
		status: string;
		payload: StateInterface["account_settings_page"]["user_data"];
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload,
	}),
};
export const fetchAccountSettingsUserCashtransactions = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.account_settings_page.user_cash_transactions = action.payload.user_cash_transactions;
		state.account_setting_loading.loading_cash_transactions = false;
	},
	prepare: (payload: {
		status: string;
		payload: StateInterface["account_settings_page"]["user_cash_transactions"];
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload,
	}),
};
export const fetchAccountSettingsUserBeegruPointstransactions = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.account_settings_page.user_beegru_points_transactions = action.payload.user_beegru_points_transactions;
		state.account_setting_loading.loading_beegru_points_transactions = false;
	},
	prepare: (payload: {
		status: string;
		payload: StateInterface["account_settings_page"]["user_beegru_points_transactions"];
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload,
	}),
};
export const fetchAccountSettingsSubscriptionsPlans = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.account_settings_page.subscription_plans = action.payload.subscription_plans;
		state.account_setting_loading.loading_subscriptions_plans = false;
	},
	prepare: (payload: {
		status: string;
		payload: StateInterface["account_settings_page"]["subscription_plans"];
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload,
	}),
};
export const fetchAccountSettingsSubscriptions = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.account_settings_page.subscriptions = action.payload.subscriptions;
		state.account_setting_loading.loading_subscriptions = false;
	},
	prepare: (payload: {
		status: string;
		payload: StateInterface["account_settings_page"]["subscriptions"];
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload,
	}),
};
export const fetchAccountSettingsFilesCount = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.account_settings_page.file_counts = action.payload.file_counts;
		state.account_settings_page.file_size_percentages = action.payload.file_size_percentages;
		state.account_settings_page.file_sizes = action.payload.file_sizes;
		state.account_settings_page.allocated_storage = action.payload.allocated_storage;
		state.account_setting_loading.loading_preferences = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload,
	}),
};

export const setBusinessProfileAboutShortDescription = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.about.short_description = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};
export const setBusinessProfileAboutLongDescription = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.about.long_description = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};
export const setBusinessProfileAboutYearEstablished = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.about.year_established = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

/* */
export const setBusinessProfileAboutOfficeAddress = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.about.office_address = action.payload;
	},
	prepare: (
		payload: Array<{
			line1: string;
			line2: string;
			city: string;
			state: string;
			country: string;
			pincode: string;
			is_hq: boolean;
		}>,
	) => ({
		payload: payload,
	}),
};
export const setBusinessProfileAboutWebsiteUrl = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.about.website_url = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};
export const setBusinessProfileAboutEducation = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.about.education = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};
export const setBusinessProfileAboutOrganization = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.about.organization = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};
export const setBusinessProfileAboutDesignation = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.about.designation = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};
export const setBusinessProfileServicesOfferedTags = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.services_offered = action.payload;
	},
	prepare: (payload: Array<string>) => ({
		payload: payload,
	}),
};
export const setBusinessProfileExpertise = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.expertise = action.payload;
	},
	prepare: (payload: Array<string>) => ({
		payload: payload,
	}),
};
export const setBusinessProfileWhyChooseUs = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.why_choose_us = action.payload;
	},
	prepare: (payload: Array<string>) => ({
		payload: payload,
	}),
};
export const setBusinessProfileBrokerage = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.agent_brokerage = action.payload;
	},
	prepare: (
		payload: Array<{
			brokerage_for: string;
			brokerage_percentage: number;
		}>,
	) => ({
		payload: payload,
	}),
};
export const setBusinessProfileVacancies = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.hiring = action.payload;
	},
	prepare: (
		payload: Array<{
			designation: string;
			experience: string;
			salary: string;
			number_of_vacancies: number;
			skills_required: Array<string> | [];
			job_description: string;
			job_qualifications: Array<string> | [];
			job_location: string;
			job_timings: string;
			job_responsibilities: Array<string> | [];
			active: boolean;
			updated_at: Date | string;
			created_at: Date | string;
		}>,
	) => ({
		payload: payload,
	}),
};
export const setBusinessProfileHonorsAndAwards = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.awards_and_honors = action.payload;
	},
	prepare: (
		payload: Array<{
			title: string;
			description: string;
			issued_by: string;
			issued_at: Date | string;
			media_url: string;
			mime_type: string;
		}>,
	) => ({
		payload: payload,
	}),
};
export const setBusinessProfileLicencesAndCertificates = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.licences_and_certifications = action.payload;
	},
	prepare: (
		payload: Array<{
			title: string;
			description: string;
			issued_by: string;
			issued_at: Date | string;
			media_url: string;
			mime_type: string;
		}>,
	) => ({
		payload: payload,
	}),
};
export const setBusinessProfileNoOfEmployees = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.no_of_employees = action.payload;
	},
	prepare: (payload: number) => ({
		payload: payload,
	}),
};
export const setBusinessProfileFAQ = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.faqs = action.payload;
	},
	prepare: (
		payload: Array<{
			question: string;
			answer: string;
		}>,
	) => ({
		payload: payload,
	}),
};
export const setBusinessProfileLanguages = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.languages = action.payload;
	},
	prepare: (
		payload: Array<{
			language: string;
			proficiency: string;
		}>,
	) => ({
		payload: payload,
	}),
};
export const setBusinessProfileEmail = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.email.email = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};
export const setBusinessProfilePhoneCountryCode = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.phone.country_code = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};
export const setBusinessProfilePhoneNumber = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.phone.phone_number = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};
export const setBusinessProfileAboutIndustryType = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.business_profile.industry_type = action.payload;
	},
	prepare: (payload: string) => ({
		payload: payload,
	}),
};

export const deleteReview = {
	reducer: (state: StateInterface, action: AnyAction) => {
		if (action.payload.source.includes("properties" && "/reviews")) {
			state.property_reviews_page.property_reviews = action.payload.payload;
		} else if (action.payload.source.includes("properties")) {
			if (action.payload.payload.type === "neighbourhood") {
				state.single_property_page.neighbourhoodReviews = action.payload.payload;
			} else {
				state.single_property_page.propertyReviews = action.payload.payload;
			}
		}
		if (action.payload.source.includes("/services" && "/reviews")) {
			state.service_reviews_page.service_reviews = action.payload.payload;
		} else if (action.payload.source.includes("services")) {
			state.single_service_page.service_reviews = action.payload.payload;
		} else if (action.payload.source.includes("users")) {
			state.single_user_profile.reviews = action.payload.payload;
		} else if (action.payload.source.includes("neighbourhood-reviews")) {
			state.neighbourhood_reviews_page.reviews = action.payload.payload;
		} else {
			state.single_business_profile.reviews = action.payload.payload;
		}
	},
	prepare: (payload: {
		source: string;
		response: {
			status: string;
			payload: StateInterface["review"];
			missingFields: Array<string> | null;
			error: string | null;
		};
	}) => ({
		payload: { payload: payload.response.payload, source: payload.source },
	}),
};

export const fetchSinglePropertyConfigAndArea = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_property_page.property.area = action.payload.property_data.area;
		state.single_property_page.property.bhk = action.payload.property_data.bhk;
		state.single_property_page.property.bathroom = action.payload.property_data.bathroom;
		state.single_property_page.property.balconies = action.payload.property_data.balconies;
		state.single_property_page.property.floors = action.payload.property_data.floors;
		state.single_property_page.property.floor_no = action.payload.property_data.floor_no;
		state.single_property_page.property.no_of_units = action.payload.property_data.no_of_units;
		state.single_property_page.property.no_of_seats = action.payload.property_data.no_of_seats;
		state.single_property_page.property.furnished = action.payload.property_data.furnished;
		state.single_property_page.property.plot_dimensions = action.payload.property_data.plot_dimensions.map(
			(plot_dimension: string) =>
				plot_dimension.replace(/_/g, " ").charAt(0).toUpperCase() + plot_dimension.replace(/_/g, " ").slice(1),
		);
		state.single_property_page.property.other_plot_dimensions = action.payload.property_data.other_plot_dimensions;
		state.property_loading.propertyConfigAndArea = false;
	},
	prepare: (payload: StateInterface["single_property_page"]) => ({
		payload: payload,
	}),
};

export const addingbeegrupointsshareproperty = {
	reducer: (state: StateInterface, action: AnyAction) => {},
	prepare: (payload: any) => ({
		payload: payload.payload,
	}),
};
export const AddingBeegruPointsForShareService = {
	reducer: (state: StateInterface, action: AnyAction) => {},
	prepare: (payload: any) => ({
		payload: payload.payload,
	}),
};
export const fetchBusinessProfilePosts = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.posts = action.payload.posts;
		state.single_business_profile.postsCount = action.payload.postsCount;
		state.business_profile_loading.businessProfilePost = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const LoadingMoreBusinessProfilePosts = {
	reducer: (state: StateInterface, action: AnyAction) => {
		Array.isArray(state.single_business_profile.posts)
			? ((state.single_business_profile.postsCount = action.payload.postsCount),
				//@ts-ignore
				state.single_business_profile.posts.push(...action.payload.posts))
			: (state.single_business_profile.posts = action.payload.posts);
	},
	prepare: (payload: any) => ({
		payload: payload.payload,
	}),
};
export const searchUserProperties = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.ofb_properties = action.payload.properties;
		state.single_user_profile.propertiesCount = action.payload.propertiesCount;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const setPropertySnackbar = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.property_snackbar.property_details = action.payload;
		state.property_snackbar.property_config = action.payload;
		state.property_snackbar.about_property = action.payload;
		state.property_snackbar.property_creator = action.payload;
		state.property_snackbar.property_about_location = action.payload;
		state.property_snackbar.property_amenities = action.payload;
		state.property_snackbar.property_developer = action.payload;
		state.property_snackbar.property_images_upload = action.payload;
		state.property_snackbar.property_images_edit = action.payload;
		state.property_snackbar.property_images_delete = action.payload;
		state.property_snackbar.property_location_coordinates = action.payload;
		state.property_snackbar.property_overview = action.payload;
		state.property_snackbar.property_reviews = action.payload;
		state.property_snackbar.property_vicinity = action.payload;
		state.property_snackbar.neighbourhood_reviews = action.payload;
	},
	prepare: (payload: boolean) => ({
		payload: payload,
	}),
};

export const setServiceSnackbar = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.service_snackbar.service_details = action.payload;
		state.service_snackbar.service_overview = action.payload;
		state.service_snackbar.service_specification = action.payload;
		state.service_snackbar.service_creator = action.payload;
		state.service_snackbar.about_service_provider = action.payload;
		state.service_snackbar.areas_of_operation = action.payload;
		state.service_snackbar.service_map = action.payload;
		state.service_snackbar.service_images_upload = action.payload;
		state.service_snackbar.service_images_edit = action.payload;
		state.service_snackbar.service_images_delete = action.payload;
	},
	prepare: (payload: boolean) => ({
		payload: payload,
	}),
};

export const setAccountSettingSnackbar = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.accountsetting_snackbar.personalinfo_snackbar = action.payload;
		state.accountsetting_snackbar.kyc_snackbar = action.payload;
		state.accountsetting_snackbar.subscription_snackbar = action.payload;
	},
	prepare: (payload: boolean) => ({
		payload: payload,
	}),
};

export const searchBusinessProfileProperties = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.properties = action.payload.properties;
		state.single_business_profile.propCount = action.payload.propertiesCount;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const searchBusinessProfileOfbProperties = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.ofb_properties = action.payload.properties;
		state.single_business_profile.ofbpropertiesCount = action.payload.propertiesCount;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const searchOfbPropertyListings = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.ofb_properties_og = action.payload.payload;
	},
	prepare: (payload: StateInterface["single_business_profile"]["searched_property"]) => ({
		payload: payload,
	}),
};
export const searchBusinessProfileServices = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.services = action.payload.services;
		state.single_business_profile.servicesCount = action.payload.servicesCount;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const searchServiceListings = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.services_og = action.payload.payload;
	},
	prepare: (payload: any) => ({
		payload: payload,
	}),
};

export const setBusinessProfileSnackbar = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.business_profile_snackbar.upload_cover_image = action.payload;
		state.business_profile_snackbar.edit_cover_image = action.payload;
		state.business_profile_snackbar.delete_cover_image = action.payload;
		state.business_profile_snackbar.upload_profile_picture = action.payload;
		state.business_profile_snackbar.edit_profile_picture = action.payload;
		state.business_profile_snackbar.delete_profile_picture = action.payload;
		state.business_profile_snackbar.update_about = action.payload;
		state.business_profile_snackbar.service_offered = action.payload;
		state.business_profile_snackbar.experties = action.payload;
		state.business_profile_snackbar.why_choose_us = action.payload;
		state.business_profile_snackbar.brokerages = action.payload;
		state.business_profile_snackbar.job_openings = action.payload;
		state.business_profile_snackbar.honors_and_awards = action.payload;
		state.business_profile_snackbar.licences_and_certificates = action.payload;
		state.business_profile_snackbar.no_of_employees = action.payload;
		state.business_profile_snackbar.business_faq = action.payload;
		state.business_profile_snackbar.language = action.payload;
		state.business_profile_snackbar.delete_property_listing = action.payload;
		state.business_profile_snackbar.delete_service_listing = action.payload;
		state.business_profile_snackbar.delete_ofb_listing = action.payload;
		state.business_profile_snackbar.delete_business_profile = action.payload;
	},
	prepare: (payload: boolean) => ({
		payload: payload,
	}),
};
export const LoadingMoreUserGivenReviews = {
	reducer: (state: StateInterface, action: AnyAction) => {
		Array.isArray(state.single_user_profile.given_reviews)
			? ((state.single_user_profile.given_reviews_count = action.payload.givenReviewsCount),
				//@ts-ignore
				state.single_user_profile.given_reviews.push(...action.payload.reviews))
			: (state.single_user_profile.given_reviews = action.payload.reviews);
	},
	prepare: (payload: any) => ({
		payload: payload.payload,
	}),
};

export const LoadingMoreUserReviews = {
	reducer: (state: StateInterface, action: AnyAction) => {
		Array.isArray(state.single_user_profile.reviews)
			? ((state.single_user_profile.reviewsCount = action.payload.reviewsCount),
				//@ts-ignore
				state.single_user_profile.reviews.push(...action.payload.reviews))
			: (state.single_user_profile.reviews = action.payload.reviews);
	},
	prepare: (payload: any) => ({
		payload: payload.payload,
	}),
};

export const fetchBusinessProfileGivenReviews = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.given_reviews = action.payload.reviews;
		state.single_business_profile.given_reviewsCount = action.payload.reviewsCount;
		state.business_profile_loading.businessProfileReviews = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const LoadingMoreBusinessProfileGivenReviews = {
	reducer: (state: StateInterface, action: AnyAction) => {
		Array.isArray(state.single_business_profile.given_reviews)
			? ((state.single_business_profile.given_reviewsCount = action.payload.givenReviewsCount),
				//@ts-ignore
				state.single_business_profile.given_reviews.push(
					//@ts-ignore
					...action.payload.reviews,
				))
			: (state.single_business_profile.given_reviews = action.payload.reviews);
	},
	prepare: (payload: any) => ({
		payload: payload.payload,
	}),
};

export const LoadingMoreBusinessProfileReviews = {
	reducer: (state: StateInterface, action: AnyAction) => {
		Array.isArray(state.single_business_profile.reviews)
			? ((state.single_business_profile.reviewsCount = action.payload.reviewsCount),
				//@ts-ignore
				state.single_business_profile.reviews.push(...action.payload.reviews))
			: (state.single_user_profile.reviews = action.payload.reviews);
	},
	prepare: (payload: any) => ({
		payload: payload.payload,
	}),
};
export const setTransactionType = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.transaction = action.payload;
	},
	prepare: (payload: StateInterface["transaction"]) => ({ payload: payload }),
};
export const setOnBoardingDetails = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.on_boarding_details = action.payload;
	},
	prepare: (payload: StateInterface["on_boarding_details"]) => ({
		payload: payload,
	}),
};
export const fetchOnBoardingBusinessTitle = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.on_boarding_title.options = action.payload.business_title;
	},
	prepare: (payload: StateInterface["on_boarding_title"]) => ({
		payload: payload,
	}),
};

export const setUserProfileSnackbar = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.user_profile_snackbar.upload_cover_image = action.payload;
		state.business_profile_snackbar.edit_cover_image = action.payload;
		state.business_profile_snackbar.delete_cover_image = action.payload;
		state.user_profile_snackbar.upload_profile_picture = action.payload;
		state.user_profile_snackbar.edit_profile_picture = action.payload;
		state.user_profile_snackbar.delete_profile_picture = action.payload;
		state.user_profile_snackbar.update_about = action.payload;
		state.user_profile_snackbar.delete_ofb_listing = action.payload;
		state.user_profile_snackbar.corrupt_file = action.payload;
	},
	prepare: (payload: boolean) => ({
		payload: payload,
	}),
};
export const NewEmployeeRquestOnBoarding = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.on_boarding_title.options = action.payload.business_title;
	},
	prepare: (payload: any) => ({
		payload: payload,
	}),
};

export const fetchUserProfileLeads = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_user_profile.leads = action.payload.leads;
		state.user_profile_loading.userProfileLeads = false;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};
export const setBottomBuyBeegruPoints = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.bottom_subscribe_buy_beegru_points = action.payload;
	},
	prepare: (payload: StateInterface["bottom_subscribe_buy_beegru_points"]) => ({
		payload: payload,
	}),
};
export const setDefaultProfileContext = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.profile_context = action.payload;
	},
	prepare: (payload: StateInterface["profile_context"]) => ({
		payload: payload,
	}),
};

export const updateReduxSession = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.redux_session = action.payload;

		if (action.payload.session !== null) {
			state.profile_context.is_business_profile = action.payload.user.dbUser.profile_context.is_business_profile;
			state.profile_context.user_id = action.payload.user.dbUser._id;
			state.profile_context.business_profile_id = action.payload.user.dbUser.profile_context.business_profile_id;
			state.profile_context.business_profile_image = action.payload.user.dbUser.profile_context.business_profile_image;
			state.profile_context.page_type = action.payload.user.dbUser.profile_context.page_type;
			state.profile_context.page_title = action.payload.user.dbUser.profile_context.is_business_profile
				? action.payload.user.dbUser.profile_context.page_title
				: "";
			state.profile_context.page_slug = action.payload.user.dbUser.profile_context.is_business_profile
				? action.payload.user.dbUser.profile_context.page_slug
				: "";
			state.profile_context.subscription_information = action.payload.user.dbUser.profile_context.is_business_profile
				? action.payload.user.dbUser.profile_context.subscription_information
				: action.payload.user.dbUser.subscription_information;
			state.profile_context.kyc_info = action.payload.user.dbUser.profile_context.is_business_profile
				? action.payload.user.dbUser.profile_context.kyc_info
				: action.payload.user.dbUser.kyc_info.kyc_status;
			state.profile_context.remember_choice = action.payload.user.dbUser.profile_context.remember_choice;

			if (
				state.redux_session !== null &&
				action.payload.user.dbUser.profile_context.is_business_profile === true &&
				action.payload.user.dbUser.pages.length < 2 &&
				action.payload.user.dbUser.pages.filter(
					(item: any) => item.page_id === action.payload.user.dbUser.profile_context.business_profile_id,
				).length === 0
			) {
				state.redux_session.user.dbUser.pages = [
					{
						_id: action.payload.user.dbUser.profile_context.business_profile_id,
						page_id: action.payload.user.dbUser.profile_context.business_profile_id,
						page_type: action.payload.user.dbUser.profile_context.page_type,
						page_title: action.payload.user.dbUser.profile_context.page_title,
						page_slug: action.payload.user.dbUser.profile_context.page_slug,
						display_picture_url: action.payload.user.dbUser.profile_context.business_profile_image,
						subscription_information: action.payload.user.dbUser.profile_context.subscription_information,
						kyc_info: action.payload.user.dbUser.profile_context.kyc_info,
					},
					...action.payload.user.dbUser.pages,
				];
			}
		}
	},
	prepare: (payload: { session: StateInterface["redux_session"] }) => ({
		payload: payload,
	}),
};

export const FetchMoreBusinessProfiles = {
	reducer: (state: StateInterface, action: AnyAction) => {
		if (state.redux_session !== null) {
			state.redux_session.user.dbUser.pages = [...state.redux_session.user.dbUser.pages, ...action.payload.pages];
		}
	},
	prepare: (payload: Array<Page>) => ({
		payload: payload,
	}),
};
export const fetchBusinessProfilePublishedProperties = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.published_properties = action.payload.PublishProperties;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const updatedPublishedProperties = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.published_properties = action.payload.PublishProperties;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const fetchBusinessProfilePublishedServices = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.published_services = action.payload.PublishProperties;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const updatedPublishedServices = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.single_business_profile.published_services = action.payload.PublishProperties;
	},
	prepare: (payload: { status: string; payload: any; missingFields: Array<string> | null; error: string | null }) => ({
		payload: payload.payload,
	}),
};

export const fetchHomePageHotPropertiesBuyLocalStorageData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.homePage.hot_properties = action.payload;
		state.home_loading.loading_hot_properties = false;
	},
	prepare: (payload: Array<any>) => ({
		payload: payload,
	}),
};
export const fetchHomePageHotPropertiesRentLocalStorageData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.homePage.hot_properties = action.payload;
		state.home_loading.loading_hot_properties = false;
	},
	prepare: (payload: Array<any>) => ({
		payload: payload,
	}),
};
export const fetchHomePageHotPropertiesOfbLocalStorageData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.homePage.hot_properties = action.payload;
		state.home_loading.loading_hot_properties = false;
	},
	prepare: (payload: Array<any>) => ({
		payload: payload,
	}),
};

export const fetchAccountSettingsRewardtransactions = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.account_settings_page.rewards_transaction = action.payload.rewards_transaction;
	},
	prepare: (payload: {
		status: string;
		payload: StateInterface["account_settings_page"]["rewards_transaction"];
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload,
	}),
};

export const fetchOneTimeRewards = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.account_settings_page.one_time_rewards = action.payload.rewards_transaction;
	},
	prepare: (payload: {
		status: string;
		payload: StateInterface["account_settings_page"]["one_time_rewards"];
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload,
	}),
};

export const fetchRecurringRewards = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.account_settings_page.recurring_rewards = action.payload.rewards_transaction;
	},
	prepare: (payload: {
		status: string;
		payload: StateInterface["account_settings_page"]["recurring_rewards"];
		missingFields: Array<string> | null;
		error: string | null;
	}) => ({
		payload: payload,
	}),
};

export const updateSearchFilterActiveOptions = {
	reducer: (
		state: StateInterface,
		action: { payload: { searchFilterId: SearchFilterId; newOptions: Array<SearchFilterOption> } },
	) => {
		state.search_filters_state[action.payload.searchFilterId].active_options = action.payload.newOptions;
	},
	prepare: (payload: { searchFilterId: SearchFilterId; newOptions: Array<SearchFilterOption> }) => ({
		payload: payload,
	}),
};

export const updateSearchFilterSelectedOptions = {
	reducer: (
		state: StateInterface,
		action: { payload: { searchFilterId: SearchFilterId; newOptions: Array<SearchFilterOption> } },
	) => {
		state.search_filters_state[action.payload.searchFilterId].selected_options = action.payload.newOptions;
	},
	prepare: (payload: { searchFilterId: SearchFilterId; newOptions: Array<SearchFilterOption> }) => ({
		payload: payload,
	}),
};

export const updateSearchFilterVisibility = {
	reducer: (state: StateInterface, action: { payload: { searchFilterId: SearchFilterId; isVisible: boolean } }) => {
		state.search_filters_state[action.payload.searchFilterId].is_visible = action.payload.isVisible;
	},
	prepare: (payload: { searchFilterId: SearchFilterId; isVisible: boolean }) => ({
		payload: payload,
	}),
};

export const updateSearchFilterApplied = {
	reducer: (state: StateInterface, action: { payload: { searchFilterId: SearchFilterId; isApplied: boolean } }) => {
		state.search_filters_state[action.payload.searchFilterId].is_applied = action.payload.isApplied;
	},
	prepare: (payload: { searchFilterId: SearchFilterId; isApplied: boolean }) => ({
		payload: payload,
	}),
};

export const updateSearchFilterIsAccordionOpen = {
	reducer: (
		state: StateInterface,
		action: { payload: { searchFilterId: SearchFilterId; isAccordionOpen: boolean } },
	) => {
		state.search_filters_state[action.payload.searchFilterId].is_accordion_open = action.payload.isAccordionOpen;
	},
	prepare: (payload: { searchFilterId: SearchFilterId; isAccordionOpen: boolean }) => ({
		payload: payload,
	}),
};

export const setSearchFilterMetrices = {
	reducer: (
		state: StateInterface,
		action: {
			payload: {
				boosted: Array<number>;
				non_boosted: Array<number>;
				filter_hash: string;
				no_of_boosted_results: number;
				no_of_non_boosted_results: number;
				total_no_of_results: number;
			};
		},
	) => {
		state.search_filter_metrices.boosted = [0];
		state.search_filter_metrices.non_boosted = [0];
		state.search_filter_metrices.filter_hash = action.payload.filter_hash;
		state.search_filter_metrices.no_of_boosted_results = action.payload.no_of_boosted_results;
		state.search_filter_metrices.no_of_non_boosted_results = action.payload.no_of_non_boosted_results;
		state.search_filter_metrices.total_no_of_results = action.payload.total_no_of_results;
	},
	prepare: (payload: {
		boosted: Array<number>;
		non_boosted: Array<number>;
		filter_hash: string;
		no_of_boosted_results: number;
		no_of_non_boosted_results: number;
		total_no_of_results: number;
	}) => ({
		payload: payload,
	}),
};

export const CreateNewView = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.view_id = action.payload.view_id;
		state.view_time_spent_id = action.payload.view_time_spent_id;
	},
	prepare: (
		payload: {
			view_id: string | ObjectId;
			view_time_spent_id: string | ObjectId;
		} | null,
	) => {
		return {
			payload: payload,
		};
	},
};
export const setSearchAnimationDetails = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.search_animation_settings = action.payload;
	},
	prepare: (payload: StateInterface["search_animation_settings"]) => ({
		payload: payload,
	}),
};
export const fetchSearchData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.SeacrhApiResponse = action.payload.payload;
		state.search_filter_metrices.boosted.push(action.payload.payload?.boosted.length);
		state.search_filter_metrices.non_boosted.push(action.payload.payload?.non_boosted.length);
		state.search_filter_metrices.filter_hash = action.payload.payload?.filter_hash;
		state.search_filter_metrices.no_of_boosted_results = action.payload.payload?.no_of_boosted_results;
		state.search_filter_metrices.no_of_non_boosted_results = action.payload.payload?.no_of_non_boosted_results;
		state.search_filter_metrices.total_no_of_results = action.payload.payload?.total_no_of_results;
	},
	prepare: (payload: SearchApiResponseInterface) => ({
		payload: payload,
	}),
};

export const fetchPropertyCardData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.property = action.payload.property_data;
	},
	prepare: (payload: PropertyInterface) => ({
		payload: payload,
	}),
};

export const ToggleBoostModal = {
	reducer: (state: StateInterface, action: AnyAction) => {
		if (action.payload) {
			if (action.payload.id) state.boost.property_id = action.payload.id;
			state.boost.boost_modal_state = action.payload.state;
		} else {
			state.boost.boost_modal_state = action.payload.state;
			state.boost = boost;
		}
	},
	prepare: (payload: { state: boolean; id?: string | ObjectId }) => ({
		payload: payload,
	}),
};

export const SetBoostPropertyLoadingState = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.boost = { ...state.boost, ...action.payload };
	},
	prepare: (payload: { boost_property_loading?: boolean; boost_submit_loading?: boolean }) => ({
		payload: payload,
	}),
};

export const SetBoostDurationAndPoints = {
	reducer: (state: StateInterface, action: AnyAction) => {
		const budget = action.payload.budget > 0 ? action.payload.budget : 0;
		const boost_start_date = new Date(action.payload.boost_start_date).toISOString();
		const boost_end_date = new Date(action.payload.boost_end_date).toISOString();
		const trigger_start_date = new Date(action.payload.trigger_start_date).toISOString();
		const trigger_end_date = new Date(action.payload.trigger_end_date).toISOString();
		state.boost.budget = budget;
		state.boost.boost_start_date = boost_start_date;
		state.boost.boost_end_date = boost_end_date;
		state.boost.trigger_start_date = trigger_start_date;
		state.boost.trigger_end_date = trigger_end_date;
	},
	prepare: (payload: {
		budget?: number;
		boost_start_date: Date | string;
		boost_end_date: Date | string;
		trigger_start_date: Date | string;
		trigger_end_date: Date | string;
	}) => ({
		payload: payload,
	}),
};

export const SetBoostLocations = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.boost.selected_location = action.payload.selected;
		if (action.payload.location) state.boost.boost_location = action.payload.location;
	},
	prepare: (payload: { selected?: BoostLocation; location?: BoostLocation[] }) => ({
		payload: payload,
	}),
};

export const SetAvgHourlyPrice = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.boost.average_hourly_rate = action.payload.avgPrice;
	},
	prepare: (payload: { avgPrice: number }) => ({
		payload: payload,
	}),
};

export const SetPropertyBoostedBy = {
	reducer: (state: StateInterface, action: AnyAction) => {
		state.boost.boosted_by = action.payload;
	},
	prepare: (payload: BoostInterface["boosted_by"]) => ({
		payload: payload,
	}),
};

export const fetchBoostPropertyDetail = {
	reducer: (state: StateInterface, action: AnyAction) => {
		const boost_data = action.payload.boost_data;
		state.boost = { ...state.boost, ...boost_data };
		state.boost.boost_property_detail = action.payload.property_data;
		state.boost.boost_data = boost_data ? { ...boost_data } : null;
		state.boost.boost_property_loading = false;
		state.boost.boosted_entity_id = action.payload.property_data._id;
		state.boost.manual_override_data = action.payload.boost_pricing_manual_overrides;
		state.boost.user_data = action.payload.user_data;
	},
	prepare: (payload: {
		user_data: UserInterface;
		boost_data: BoostInterface;
		propertyDetail: BoostPropertyInterface;
		boost_pricing_manual_overrides: BoostPricingManualOverrideInterface[];
	}) => ({
		payload: payload,
	}),
};

export const createBoostPropertyData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		const boost_data = action.payload.boost;
		state.boost.boost_data = boost_data;
		state.boost.boost_submit_loading = false;
	},
	prepare: (payload: { user: UserInterface; boost: BoostInterface; boostLog: BoostLogInterface }) => ({
		payload: payload,
	}),
};

export const updateBoostPropertyData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		const boost_data = action.payload.boost;
		if (boost_data) state.boost.boost_data = boost_data;
		state.boost.boost_submit_loading = false;
	},
	prepare: (payload: { user: UserInterface; boost: BoostInterface; boostLog: BoostLogInterface }) => ({
		payload: payload,
	}),
};

export const cancelBoostPropertyData = {
	reducer: (state: StateInterface, action: AnyAction) => {
		// const boost_data = action.payload.boost;
		state.boost.boost_data = null;
		state.boost = { ...state.boost, ...action.payload.default };
		state.boost.boost_submit_loading = false;
	},
	prepare: (payload: {
		user: UserInterface;
		c: BoostInterface;
		boost: BoostInterface;
		boostLog: BoostLogInterface;
	}) => ({
		payload: payload,
	}),
};
