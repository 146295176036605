/*

? First, let's import the interface that will describe the shape of our boost object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the boost object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

export const property_status_history: StateInterface["property_status_history"] = {
	_id: "",
	status_history: {
		_id_copy: "",
		property_id: "",
		status: "",
		changed_by: {
			is_employee: false,
			employee_id: "",
			user_id: "",
		},
	},
	status_changed_at: "",
};
