import {
	SearchFilter,
	SearchFilterOptionText,
	SearchFilterOptionTextActive,
} from "../../../redux-magic/sub-interfaces/search-types";

export const _z_business_profile_type: SearchFilter = {
	id: "business_profile_type",
	title: "Business Profile Type",
	level: 0,
	index: 25,
	presentation_type: "radio_buttons",
	multi: false,
	affects: [],
	affected_by: ["search_type"],
	priority: 1,
	is_advanced: false,
	included_in_search_bar: true,
	option_type: "text",
	possible_options: [
		{
			id: "agents",
			title: "Agents",
			value: "agents",
		},
		{
			id: "developers",
			title: "Developers",
			value: "developers",
		},
		{
			id: "landowners",
			title: "Landowners",
			value: "landowners",
		},
		{
			id: "professionals",
			title: "Professionals",
			value: "professionals",
		},
	] as Array<SearchFilterOptionText>,
	active_options: [
		{
			id: "agents",
			title: "Agents",
			value: "agents",
			is_selected: true,
		},
		{
			id: "developers",
			title: "Developers",
			value: "developers",
			is_selected: false,
		},
		{
			id: "landowners",
			title: "Landowners",
			value: "landowners",
			is_selected: false,
		},
		{
			id: "professionals",
			title: "Professionals",
			value: "professionals",
			is_selected: false,
		},
	] as Array<SearchFilterOptionTextActive>,
	selected_options: [
		{
			id: "agents",
			title: "Agents",
			value: "agents",
		},
	] as Array<SearchFilterOptionText>,
	db_collection: "business_profiles",
	db_field: "business_profile_type",
	is_visible: true,
	is_applied: false,
	is_accordion_open: false,
};
