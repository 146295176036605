import { SearchFilter, SearchFilterId } from "../../../../redux-magic/sub-interfaces/search-types";

import {
	amenities,
	area,
	available_from,
	balconies,
	bathrooms,
	bedrooms,
	budget,
	business_profile_type,
	floors,
	furnishing,
	land_facing,
	listed_by,
	location,
	plot_dimensions,
	possession_date,
	property_category,
	property_status,
	property_type,
	search_type,
	seats,
	security_deposit,
	service_category,
	service_subtype,
	service_type,
	transaction_type,
	unit_facing,
} from "../index";

export const SearchFiltersMap: Map<SearchFilterId, SearchFilter> = new Map([
	[search_type.id, search_type],
	[location.id, location],
	[transaction_type.id, transaction_type],
	[property_category.id, property_category],
	[property_type.id, property_type],
	[budget.id, budget],
	[security_deposit.id, security_deposit],
	[bedrooms.id, bedrooms],
	[area.id, area],
	[bathrooms.id, bathrooms],
	[balconies.id, balconies],
	[seats.id, seats],
	[plot_dimensions.id, plot_dimensions],
	[property_status.id, property_status],
	[possession_date.id, possession_date],
	[available_from.id, available_from],
	[unit_facing.id, unit_facing],
	[land_facing.id, land_facing],
	[floors.id, floors],
	[furnishing.id, furnishing],
	[amenities.id, amenities],
	[service_category.id, service_category],
	[service_type.id, service_type],
	[service_subtype.id, service_subtype],
	[listed_by.id, listed_by],
	[business_profile_type.id, business_profile_type],
]);

export const SearchFiltersMapTest: Map<SearchFilterId, SearchFilter> = new Map([
	[search_type.id, search_type],
	[location.id, location],
	[transaction_type.id, transaction_type],
	[property_category.id, property_category],
	[property_type.id, property_type],
	[budget.id, budget],
	[security_deposit.id, security_deposit],
	[bedrooms.id, bedrooms],
	[area.id, area],
	[bathrooms.id, bathrooms],
	[balconies.id, balconies],
	[seats.id, seats],
	[plot_dimensions.id, plot_dimensions],
	[property_status.id, property_status],
	[possession_date.id, possession_date],
	[available_from.id, available_from],
	[unit_facing.id, unit_facing],
	[land_facing.id, land_facing],
	[floors.id, { ...floors, is_applied: true }],
	[furnishing.id, furnishing],
	[amenities.id, amenities],
	[service_category.id, service_category],
	[service_type.id, service_type],
	[service_subtype.id, service_subtype],
	[listed_by.id, listed_by],
	[business_profile_type.id, business_profile_type],
]);
