import {
	SearchFilter,
	SearchFilterOptionText,
	SearchFilterOptionTextActive,
} from "../../../redux-magic/sub-interfaces/search-types";

export const property_category_options: Array<SearchFilterOptionText> = [
	{
		id: "residential",
		title: "Residential",
		value: "residential",
	},
	{
		id: "commercial",
		title: "Commercial",
		value: "commercial",
	},
	{
		id: "agricultural",
		title: "Agricultural",
		value: "agricultural",
	},
	{
		id: "warehousing",
		title: "Warehousing",
		value: "warehousing",
	},
	{
		id: "industrial",
		title: "Industrial",
		value: "industrial",
	},
];
export const property_category_options_active: Array<SearchFilterOptionTextActive> = [
	{
		id: "residential",
		title: "Residential",
		value: "residential",
		is_selected: true,
	},
	{
		id: "commercial",
		title: "Commercial",
		value: "commercial",
		is_selected: false,
	},
	{
		id: "agricultural",
		title: "Agricultural",
		value: "agricultural",
		is_selected: false,
	},
	{
		id: "warehousing",
		title: "Warehousing",
		value: "warehousing",
		is_selected: false,
	},
	{
		id: "industrial",
		title: "Industrial",
		value: "industrial",
		is_selected: false,
	},
];
export const ofb_property_category_options: Array<SearchFilterOptionText> = [
	{
		id: "public_infrastructure",
		title: "Public Infrastructure",
		value: "public_infrastructure",
	},
	{
		id: "joint_development",
		title: "Joint Development",
		value: "joint_development",
	},
	{
		id: "joint_venture",
		title: "Joint Venture",
		value: "joint_venture",
	},
	{
		id: "residential",
		title: "Residential",
		value: "residential",
	},
	{
		id: "commercial",
		title: "Commercial",
		value: "commercial",
	},
	{
		id: "agricultural",
		title: "Agricultural",
		value: "agricultural",
	},
	{
		id: "warehousing",
		title: "Warehousing",
		value: "warehousing",
	},
	{
		id: "industrial",
		title: "Industrial",
		value: "industrial",
	},
];
export const ofb_property_category_options_active: Array<SearchFilterOptionTextActive> = [
	{
		id: "public_infrastructure",
		title: "Public Infrastructure",
		value: "public_infrastructure",
		is_selected: true,
	},
	{
		id: "joint_development",
		title: "Joint Development",
		value: "joint_development",
		is_selected: false,
	},
	{
		id: "joint_venture",
		title: "Joint Venture",
		value: "joint_venture",
		is_selected: false,
	},
	{
		id: "residential",
		title: "Residential",
		value: "residential",
		is_selected: false,
	},
	{
		id: "commercial",
		title: "Commercial",
		value: "commercial",
		is_selected: false,
	},
	{
		id: "agricultural",
		title: "Agricultural",
		value: "agricultural",
		is_selected: false,
	},
	{
		id: "warehousing",
		title: "Warehousing",
		value: "warehousing",
		is_selected: false,
	},
	{
		id: "industrial",
		title: "Industrial",
		value: "industrial",
		is_selected: false,
	},
];

export const _d_property_category: SearchFilter = {
	id: "property_category",
	title: "Property Category",
	level: 1,
	index: 3,
	presentation_type: "dropdown",
	multi: false,
	affects: ["property_type"],
	affected_by: ["search_type"],
	priority: 1,
	is_advanced: false,
	included_in_search_bar: true,
	option_type: "text",
	possible_options: [...property_category_options, ...ofb_property_category_options] as Array<SearchFilterOptionText>,
	active_options: [...property_category_options_active] as Array<SearchFilterOptionTextActive>,
	selected_options: [
		{
			id: "residential",
			title: "Residential",
			value: "residential",
		},
	] as Array<SearchFilterOptionText>,
	db_collection: "properties",
	db_field: "property_category",
	is_visible: true,
	is_applied: true,
	is_accordion_open: true,
};
